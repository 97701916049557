import React from 'react';
import { Link } from 'react-router-dom';


export default function Logo() {
  const baseUrl = window.location.origin;
  return (
    <Link rel="preload" to="/">
      <picture>
        <img alt="TackTech Logo" className="ml-2 h-[2.1rem] w-auto hover:opacity-50" src={`${baseUrl}/logo-no-bg.svg`} />
      </picture>
    </Link>
  )
}