import React, { createContext, useContext, useState } from 'react';

const InitialMessageContext = createContext();

export const useInitialMessage = () => useContext(InitialMessageContext);

export const InitialMessageProvider = ({ children }) => {
  const [initialMessage, setInitialMessage] = useState('');

  return (
    <InitialMessageContext.Provider value={{ initialMessage, setInitialMessage }}>
      {children}
    </InitialMessageContext.Provider>
  );
};