import Cookies from 'js-cookie';

const setCookie = (name, value, days) => {
  Cookies.set(name, value, { expires: days, path: '/' });
};

const getCookie = (name) => {
  return Cookies.get(name);
};

const removeCookie = (name) => {
  Cookies.remove(name, { path: '/' });
};

export { setCookie, getCookie, removeCookie };