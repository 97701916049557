export const ADD_MESSAGE = 'ADD_MESSAGE';
export const UPDATE_LAST_LOADING_MESSAGE = 'UPDATE_LAST_LOADING_MESSAGE';
export const SET_MESSAGES = 'SET_MESSAGES';
export const RESET_MESSAGES = 'RESET_MESSAGES';

export const TOOL_USE_STARTING = 'TOOL_USE_STARTING';
export const TOOL_USE_PROGRESS_DELTA = 'TOOL_USE_PROGRESS_DELTA';
export const TOOL_USE_DONE = 'TOOL_USE_DONE';
export const TOOL_USE_ERRORED = 'TOOL_USE_ERRORED';
export const TOOL_USE_RESTRICTED = 'TOOL_USE_RESTRICTED';

export const MESSAGE_STARTING = 'MESSAGE_STARTING';
export const MESSAGE_PROGRESS_DELTA = 'MESSAGE_PROGRESS_DELTA';
export const MESSAGE_DONE = 'MESSAGE_DONE';
export const MESSAGE_ERRORED = 'MESSAGE_ERRORED';

export const DELETE_UPSELL_MESSAGES = 'DELETE_UPSELL_MESSAGES';

export const ERROR = 'ERROR';
