import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import ProfilePicture from './ProfilePicture';

const MyProfileLink = ({ disableLink, hideName, isSidebar }) => {
  const { user, loading, error } = useContext(UserContext);

  if (loading) {
    return (
      <div className="text-xs font-semibold leading-6 text-slate-300">
        <span className="inline-block h-6 bg-slate-700 rounded w-24 animate-pulse"></span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-xs font-semibold leading-6 text-slate-300 invisible">
        <span className="inline-block h-6 bg-slate-700 rounded w-24"></span>
      </div>
    );
  }

  // Conditional button rendering for sidebar with matching button styling
  if (isSidebar) {
    return (
      <Link
        to="/profile"
        className="flex items-center justify-center px-3 py-2 rounded text-amber-400 font-bold text-xs bg-zinc-900 border border-amber-400 hover:opacity-75 active:bg-zinc-700 w-full"
      >
        <span className="mx-2 truncate">{'My Agents'}</span>
      </Link>
    );
  }

  // Regular profile link with optional avatar and username
  const profileContent = <ProfilePicture username={user.details.username} hideName={hideName} />;

  if (disableLink) {
    return (
      <div className="text-xs font-semibold leading-6 text-slate-300 hover:text-amber-400 flex items-center cursor-pointer">
        {profileContent}
      </div>
    );
  }

  return (
    <Link to="/profile" className="text-xs font-semibold leading-6 text-slate-300 hover:text-amber-400 flex items-center">
      {profileContent}
    </Link>
  );
};

export default MyProfileLink;
