import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeExternalLinks from 'rehype-external-links';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useState } from "react";

const MarkdownRenderer = ({ message }) => {
  const [copiedCodeBlocks, setCopiedCodeBlocks] = useState({});

  async function copyClick(text) {
    const type = "text/plain";
    const blob = new Blob([text], { type });
    const data = [new ClipboardItem({ [type]: blob })];
    await navigator.clipboard.write(data);
  }

  function handleCopyCodeClick(index, text) {
    copyClick(text);
    setCopiedCodeBlocks((prevState) => ({
      ...prevState,
      [index]: true,
    }));
    setTimeout(() => {
      setCopiedCodeBlocks((prevState) => ({
        ...prevState,
        [index]: false,
      }));
    }, 355);
  }

  return (
    <div className='px-5 prose prose-strong:text-slate-100 prose-li:text-slate-100 text-slate-100 prose-a:text-blue-600 prose-headings:text-slate-100 text-wrap prose-code:text-orange-400 overflow-x-auto prose-pre:bg-zinc-900'>
      <ReactMarkdown
        rehypePlugins={[[rehypeExternalLinks, { target: '_blank' }]]}
        remarkPlugins={[remarkGfm]}
        components={{
          code(props) {
            const { children, className, node, ...rest } = props;
            const match = /language-(\w+)/.exec(className || '');
            const index = node.position.start.line;
            return match ? (
              <>
                <div className="inline-flex w-full justify-between mb-2 border-b-2 border-zinc-700 pb-2">
                  <span className="font-bold">Code Snippet</span>
                  <button
                    onClick={() => handleCopyCodeClick(index, children)}
                    className='relative flex items-center text-amber-400 hover:text-amber-600 ml-5'
                  >
                    <DocumentDuplicateIcon className='h-5 w-5 mr-1' />
                    Copy code
                    <div
                      className={`${
                        !copiedCodeBlocks[index] && "hidden"
                      } absolute rounded-sm right-3 bg-white px-1 transition-opacity ease-out duration-700 opacity-100`}
                    >
                      Copied!
                    </div>
                  </button>
                </div>
                <SyntaxHighlighter
                  {...rest}
                  PreTag={CustomPreTag}
                  style={a11yDark}
                  children={String(children).replace(/\n$/, '')}
                  language={match[1]}
                  wrap={true}
                />
              </>
            ) : (
              <code {...rest}>{children}</code>
            );
          },
        }}
      >
        {message}
      </ReactMarkdown>
    </div>
  );
};

const CustomPreTag = ({ children }) => <div className="p-2 m-2">{children}</div>;

export default MarkdownRenderer;