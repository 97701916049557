import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { Cog6ToothIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import MyProfileLink from './MyProfile';
import { useAuth } from '../contexts/AuthContext'; // Import the useAuth hook

type LoginProps = {
  isLoggedIn: boolean;
  handleLoginClick: () => void;
  handleSignupClick: () => void;
  handleLogoutClick: () => void;
};

export default function LoginButton({
  isLoggedIn,
  handleLoginClick,
  handleSignupClick,
  handleLogoutClick,
}: LoginProps) {
  const { user } = useAuth(); // Get the user object from useAuth

  // Check if the user has the "admin" role
  const isAdmin = user?.roles?.includes('admin');

  return (
    <>
      {isLoggedIn ? (
        <Menu as="div" className="pr-1.5">
          <MenuButton className="flex items-center focus:outline-none hover:opacity-75">
            <MyProfileLink
              disableLink={true}
              hideName={true}
              isSidebar={false}
            />
          </MenuButton>
          <MenuItems
            anchor="bottom end"
            className="w-40 bg-zinc-700 mt-1.5 rounded-md shadow-lg z-50"
          >
            <MenuItem>
              <Link
                to="/settings"
                className="flex items-center px-4 py-2 text-sm/8 text-white hover:bg-zinc-600 rounded-t-md"
              >
                <Cog6ToothIcon className="h-5 w-5 mr-2" />
                Settings
              </Link>
            </MenuItem>
            {isAdmin && (
              <MenuItem>
                <Link
                  to="/admin"
                  className="flex items-center px-4 py-2 text-sm/8 text-white hover:bg-zinc-600"
                >
                  <Cog6ToothIcon className="h-5 w-5 mr-2" />
                  Admin
                </Link>
              </MenuItem>
            )}
            <MenuItem>
              <button
                onClick={handleLogoutClick}
                className="flex items-center w-full text-left px-4 py-2 text-sm/8 text-white rounded-b-md hover:bg-zinc-600 border-t border-gray-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6 -ml-1 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9"
                  />
                </svg>
                Logout
              </button>
            </MenuItem>
          </MenuItems>
        </Menu>
      ) : (
        <div className="md:mr-2 mr-1 flex items-center">
          <button
            onClick={handleLoginClick}
            className="bg-amber-400 text-black px-1 md:px-3 py-2 md:py-1.5 text-xs md:text-base rounded-md hover:opacity-75 focus:outline-none mr-1"
          >
            Login
          </button>
          <button
            onClick={handleSignupClick}
            className="bg-zinc-900 text-amber-400 border-2 border-yellow-500 px-1 py-2 md:py-1.5 text-xs md:text-base rounded-md hover:opacity-75 active:bg-zinc-700 focus:outline-none"
          >
            Sign up
          </button>
        </div>
      )}
    </>
  );
}
