export const monthlyChatSubscriberPlan = {
    name: 'Monthly Subscription',
    id: 'tier-monthly',
    href: '#',
    description: '',
    period: "monthly",
    lookup_key: "tacktech_monthly",
    features: [
      "Unlimited messaging",
      "Image generation",
      //"Remove private agent limit" //
    ]
  };
  
  export const yearlyChatSubscriberPlan = {
    name: 'Yearly Subscription',
    id: 'tier-yearly',
    href: '#',
    description: '',
    period: "yearly",
    lookup_key: "tacktech_yearly",
    features: [
      "Unlimited messaging",
      "Image generation",
     // "Remove private agent limit" //
    ]
  };
  
  export const plans = [
    monthlyChatSubscriberPlan,
    yearlyChatSubscriberPlan
  ];
  