// api.js
import ApiError from './ApiError';

const BASE_URL = process.env.REACT_APP_TACKTECH_API_URL;
export const WS_URL = process.env.REACT_APP_TACKTECH_WS_URL;

let getSessionId = null;
let renewSession = null;
const headers = {
  Accept: 'application/json, text/plain',
};
const options = { headers, credentials: 'include' };

const getCsrfToken = async () => {
  const response = await fetch(`${BASE_URL}api/auth/csrf`, options);
  const data = await response.json();
  return data["csrf-token"];
};


export const initApi = (sessionRetrievalFunction, renewFunction) => {
  console.log(
    'Calling init api with session retrieval function',
    sessionRetrievalFunction,
    'and renew session function',
    renewFunction
  );
  getSessionId = sessionRetrievalFunction;
  renewSession = renewFunction;
};
export const fetchApi = async (
  endpoint,
  method,
  data = null,
  isStreaming = false
) => {
  const headers = {
    Accept: 'application/json, text/plain',
  };

  if (getSessionId) {
    const sessionId = getSessionId();
    if (sessionId) {
      headers['session-id'] = sessionId;
    }
    console.info('Session ID was', sessionId);
  }

  let body = null;
  if (data) {
    if (data instanceof FormData) {
      body = data;
    } else {
      headers['Content-Type'] = 'application/json';
      body = JSON.stringify(data);
    }
  }

  const options = { method, headers, body, credentials: 'include' };

  if (method === 'GET' || method === 'HEAD') {
    delete options.body;
  }

  if (method === 'POST' || method === 'PATCH' || method === 'DELETE') {
    const csrfToken = await getCsrfToken();
    headers['x-csrf-token'] = csrfToken;
  }

  const response = await fetch(`${BASE_URL}${endpoint}`, options);
  const contentType = response.headers.get('Content-Type');
  if (isStreaming) {
    if (!response.ok) {
      const responseData = contentType.includes('application/json')
        ? await response.json()
        : await response.text();
      if (response.status === 401 && renewSession) {
        renewSession();
      }
      throw new ApiError(
        response.status,
        responseData.message || 'An error occurred',
        responseData.fields
      );
    }
    return response;
  }

  if (response.ok) {
    return contentType.includes('application/json')
      ? await response.json()
      : await response.text();
  } else {
    const responseData = contentType.includes('application/json')
      ? await response.json()
      : await response.text();
    if (response.status === 401 && renewSession) {
      renewSession();
    }
    throw new ApiError(
      response.status,
      responseData.message || 'An error occurred',
      responseData.fields
    );
  }
};

export const apiPost = (endpoint, data, isStreaming = false) =>
  fetchApi(endpoint, 'POST', data, isStreaming);
export const apiGet = (endpoint, isStreaming = false) =>
  fetchApi(endpoint, 'GET', null, isStreaming);
export const apiPatch = (endpoint, data) => fetchApi(endpoint, 'PATCH', data);
export const apiDelete = (endpoint) => fetchApi(endpoint, 'DELETE');
export const agentList = async ({ type, name, limit, skip }) => {
  try {
    const response = await fetchApi(
      `api/agents?type=${type}&name=${name}&limit=${limit}&skip=${skip}`,
      'GET'
    );
    return response;
  } catch (error) {
    console.error('Error fetching agent list:', error);
    throw new ApiError(error.status, error.message);
  }
};

export const getAvatarUrl = (objectPath) => {
  return `https://tacktech-user-images.s3.amazonaws.com/${objectPath}`;
};
