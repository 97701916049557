// Utility function to truncate text
const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + '...';
};

export default truncateText;

export const getFirstSentence = (text) => {
  // Regular expression to match sentences ending with period, exclamation mark, or question mark
  const match = text.match(/[^.!?]+[.!?]+/);
  return match ? match[0] : text;
};

export const findUniqueObjects = (arr1, arr2) => {
  const uniqueMap = new Map();
  const addUniqueObjects = (array) => {
    array.forEach((obj) => {
      if (!uniqueMap.has(obj.id)) {
        uniqueMap.set(obj.id, obj);
      }
    });
  };
  addUniqueObjects(arr1);
  addUniqueObjects(arr2);
  const uniqueObjects = Array.from(uniqueMap.values());
  return uniqueObjects;
};

export const getFormatedCount = (count) => {
  if (count >= 1000000) {
    const formatedCount = (count / 1000000).toFixed(1);
    return `${formatedCount}m`;
  } else if (count >= 1000) {
    const formatedCount = (count / 1000).toFixed(1);
    return `${formatedCount}k`;
  }
  return String(count);
};
