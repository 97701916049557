import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

type NewChatButtonProps = {
  id: string;
  chatId?: string;
};

export default function NewChatButton({ id, chatId }: NewChatButtonProps) {
  function handleClick() {
    if (chatId) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      toast('New chat was created!', {
        icon: '☑️',
        duration: 3000,
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
    }
  }

  return (
    <Link
      to={`/agents/${id}/chat`}
      onClick={handleClick}
      className={`flex items-center px-1 md:px-3 py-1 md:py-1.5 text-white bg-zinc-700 ${
        !chatId ? 'opacity-50 cursor-default' : 'active:bg-zinc-800'
      } rounded-md`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
      <p className="ml-1 text-xs hidden md:block">New Chat</p>
    </Link>
  );
}
