const primary = "text-amber-400";
const primary_footer = "bg-zinc-900";
const primary_hover = "hover:text-amber-400";
const secondary = "bg-stone-700";
const background = 'bg-zinc-800';
const card_color = "bg-zinc-900";
const button_gold = "bg-amber-400";
const hover_fade = "hover:opacity-75 transition-opacity duration-300";

export { primary, primary_footer, primary_hover, secondary, background, card_color, button_gold, hover_fade };
