import React, { useEffect, useState } from 'react';
import { apiGet } from '../../tacktech/api';
import AgentCard from './AgentCard';
import { primary } from '../../utilities/ColorPalette';
import { findUniqueObjects } from '../../utilities/utilities';
import Helmet from 'react-helmet';
import { Link } from "react-router-dom"
import { useSidebarCollapse } from '../../contexts/SidebarCollapseContext';

const AgentList = () => {
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [limit] = useState(100);
  const [skip] = useState(0);
  const { setIsCollapsed } = useSidebarCollapse();

  useEffect(() => {
    const fetchAgents = async (limit, skip) => {
      setLoading(true);
      try {
        const response = await apiGet(`api/agents?type=user&name=todo&limit=${limit}&skip=${skip}`);
        const res = [];
        for (const agent of response) {
          const agentInfo = await apiGet(`api/agents/${agent.id}`);
          if (!agentInfo) return { ...agentInfo, agentImg: '' };
          res.push({ ...agent, tagline: agentInfo.tagline, avatar_url: agentInfo.avatar_url });
        }
        setAgents((prevAgents) => findUniqueObjects(prevAgents, res));
        setError(null);
      } catch (error) {
        setError(error.message || "Failed to fetch agents");
      } finally {
        setLoading(false);
      }
    };
    fetchAgents(limit, skip);
  }, [skip, limit]);


  useEffect(() => {
    if (window.matchMedia('screen and (max-width: 768px)').matches) {
      setIsCollapsed(true);
    }

  }, [setIsCollapsed]);

  if (loading && skip === 0) {
    return <div className="text-center py-6 text-gray-200 animate-pulse">Loading...</div>;
  }

  if (error) {
    return <div className="text-center py-6 text-red-500">Error: {error}</div>;
  }

  return (
    <div className="container mx-auto">
      <Helmet>
        <title>TackTech - Profile</title>
        <meta name="description" content={"Explore and interact with a variety of AI chatbots and assistants tailored to meet your needs."} />
        <meta name="keywords" content="AI, chatbots, assistant, support, interactive, machine learning, artificial intelligence" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={"TackTech - AI Chatbots and Assistants"} />
        <meta property="og:description" content={"Explore and interact with a variety of AI chatbots and assistants tailored to meet your needs."} />
        <meta property="og:image" content={"logo.jpg"} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={"TackTech - AI Chatbots and Assistants"} />
        <meta name="twitter:description" content={"Explore and interact with a variety of AI chatbots and assistants tailored to meet your needs."} />
        <meta name="twitter:image" content={"logo.jpg"} />

        {/* Additional Meta Tags */}
        <meta name="author" content="TackTech Inc." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* Favicon */}
        <link rel="icon" href="favicon.ico" type="image/x-icon" />
      </Helmet>
      <div className="flex justify-between items-center mt-4 mb-2 px-2">
        <h1 className={`${primary} text-xl font-semibold leading-7`}>Agents</h1>
      </div>
      {agents.length > 0 ?
        <div className="w-full flex flex-col space-y-2 md:mx-1">
          {agents.map((agent) => (
            <AgentCard key={agent.id} agent={agent} />
          ))}
        </div> : <CreateAgentCTA />}
    </div>
  );
};


export default AgentList;



const CreateAgentCTA = () => (
  <Link to="/agents/create">
    <div className="mx-2 relative bg-zinc-700 hover:bg-zinc-600 hover:border-zinc-400/80 rounded-lg p-1 md:p-2 border-zinc-500/80 border border-dashed">
      <div className="flex justify-center items-center h-20">
        <p className="text-zinc-300 text-xs md:text-md text-center max-w-[75%]">We couldn't find any personalized agents for you. Click here to create one!</p>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.25" stroke="currentColor" className="h-8 w-8 text-zinc-300 ml-1 mt-0.5 p-1">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
        </svg>
      </div>
    </div>
  </Link >
);
