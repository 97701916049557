import { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import SubscriptionSettings from '../components/SubscriptionSettings';
import Helmet from 'react-helmet';
import PageSidebarTemplate from '../templates/PageSidebarTemplate';
import UpdateUsername from '../components/UpdateUsername';
import ConsentSettings from '../components/ConsentSettings'; 
import UpdateDateOfBirth from '../components/UpdateDateOfBirth'; 

export default function Settings() {
  // We would like to have up to date information on this page so whenever we come here we will
  // refresh the user data from the server to ensure it is up to date.
  const { user, loading, fetchUserData } = useAuth();
  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    if (!hasFetched) {
      fetchUserData();
      setHasFetched(true);
    }
  }, [hasFetched, fetchUserData]);

  if (!user) {
    return (
      <PageSidebarTemplate>
        <div className="flex flex-col items-center justify-center h-full p-4">
          <h1 className="text-3xl font-bold text-amber-400">You must be logged in to view this page.</h1>
        </div>
      </PageSidebarTemplate>
    );
  }

  return (
    <PageSidebarTemplate>
      <div className="flex justify-center min-h-screen bg-zinc-800">
        <Helmet>
          <title>TackTech - Settings</title>
          <meta name="description" content={"Explore and interact with a variety of AI chatbots and assistants tailored to meet your needs."} />
          <meta name="keywords" content="AI, chatbots, assistant, support, interactive, machine learning, artificial intelligence" />

          {/* Open Graph Meta Tags */}
          <meta property="og:title" content={"TackTech - AI Chatbots and Assistants"} />
          <meta property="og:description" content={"Explore and interact with a variety of AI chatbots and assistants tailored to meet your needs."} />
          <meta property="og:image" content={"logo.jpg"} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />

          {/* Twitter Card Meta Tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={"TackTech - AI Chatbots and Assistants"} />
          <meta name="twitter:description" content={"Explore and interact with a variety of AI chatbots and assistants tailored to meet your needs."} />
          <meta name="twitter:image" content={"logo.jpg"} />

          {/* Additional Meta Tags */}
          <meta name="author" content="TackTech Inc." />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />

          {/* Favicon */}
          <link rel="icon" href="favicon.ico" type="image/x-icon" />
        </Helmet>
        <div className="w-full max-w-2xl p-2 overflow-y-auto md:pt-10">
          <h1 className="sr-only text-white">Account Settings</h1>
          <div className="flex flex-col space-y-6">
            <SubscriptionSettings loading={loading} user={user} />
            <UpdateUsername />
            <UpdateDateOfBirth />
            <ConsentSettings />
              {/* <Usage /> */}
            </div>
          </div>
        </div>
    </PageSidebarTemplate>
    /*
    <div className="divide-y divide-white/5">
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7 text-white">Personal Information</h2>
        </div>
        <PersonalInformationForm />
      </div>

      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7 text-white">Change password</h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            Update your password associated with your account.
          </p>
        </div>
        <PasswordChangeForm />
      </div>

      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7 text-white">Log out other sessions</h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            Please enter your password to confirm you would like to log out of your other sessions across all of
            your devices.
          </p>
        </div>
        <LogoutSessionsForm />
      </div>

      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7 text-white">Delete account</h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            No longer want to use our service? You can delete your account here. This action is not reversible.
            All information related to this account will be deleted permanently.
          </p>
        </div>
        <DeleteAccountForm />
      </div>
    </div>
  */
  );
}
