import React from 'react';

const FormSection = ({ title, description, children }) => (
  <div className="pt-4">
    <h2 className="text-sm font-bold leading-7 text-amber-400">{title}</h2>
    <p className="mt-1 text-sm leading-6 text-white mb-4">{description}</p>
    {children}
  </div>
);

export default FormSection;