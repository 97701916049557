import React from 'react';
import { Link } from 'react-router-dom';
import { PencilIcon } from '@heroicons/react/20/solid';
import truncateText from '../../utilities/utilities';
import { getAvatarUrl } from '../../tacktech/api';

const AgentCard = ({ agent }) => {
  return (
    <div className="relative bg-zinc-700 hover:bg-zinc-600 shadow-lg rounded-lg p-1 md:p-2 min-h-24">
      <Link state={window.location.pathname} to={`/agents/${agent.id}`} className="absolute top-2 right-2 text-violet-500 hover:text-violet-600 p-1">

        <PencilIcon className="h-3 w-3 md:h-5 md:w-5 " />
      </Link>
      <Link to={`/agents/${agent.id}/chat`} className="flex">
        <img
          className="object-cover h-24 w-24 rounded-md bg-gray-100 mr-4 shadow-md"
          src={getAvatarUrl(agent.avatar_url)}
          alt={`agent-${agent.name}`}
        />
        <div className="flex flex-col md:space-y-1 mt-1">
          <h2 className="text-md font-semibold text-gray-200 hover:underline">
            {truncateText(agent.name, 30)}
          </h2>
          <p className="text-gray-300 text-xs md:text-sm md:pr-10 text-clip">
            {truncateText(agent.description, 90)}
          </p>
        </div>
      </Link>

    </div>
  );
};

export default AgentCard;
