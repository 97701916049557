import React from "react";

const RadioInput = ({ label, value, checked, onChange, description }) => (
    <div className="flex items-start">
      <div className="flex items-center h-5">
        <input
          name="visibility"
          type="radio"
          value={value}
          checked={checked}
          onChange={onChange}
          className="focus:ring-indigo-500 h-4 w-4 text-black border-gray-300"
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={value} className="font-medium text-amber-400">{label}</label>
        <p className="text-white">{description}</p>
      </div>
    </div>
  );

export default RadioInput;
