import { apiGet, apiPost } from './api';
import ApiError from './ApiError';
import {
  MESSAGE_PROGRESS_DELTA,
  MESSAGE_DONE,
  MESSAGE_ERRORED,
  TOOL_USE_STARTING,
  TOOL_USE_DONE,
  TOOL_USE_RESTRICTED,
  TOOL_USE_PROGRESS_DELTA
} from '../tacktech/chat/actions';

export const handleApiError = (error, customMessage) => {
  console.error(customMessage, error);
  throw new ApiError(error.status, error.message);
};

const processChunk = (chunk, onMessage) => {
  const parsedChunk = JSON.parse(chunk);
  const eventType = parsedChunk?.object;
  switch (eventType) {
    case 'permission.denied':
      if (parsedChunk.message === "You do not have permission to use tools.") {
        onMessage(TOOL_USE_RESTRICTED, parsedChunk);
      }
      break;
    case 'chat.completion.chunk':
      const content = parsedChunk?.choices?.[0]?.delta?.content || '';
      const isFinished = parsedChunk?.choices?.[0]?.finish_reason !== null;

      if ((onMessage && content)) {
        onMessage(MESSAGE_PROGRESS_DELTA, content);
      }

      if (isFinished) {
        onMessage(MESSAGE_DONE, '');

      }
      break;
    case 'tool.usage':
      const usageType = parsedChunk?.type;
      switch (usageType) {
        case TOOL_USE_STARTING:
          onMessage(TOOL_USE_STARTING, parsedChunk);
          break;
        case TOOL_USE_PROGRESS_DELTA:
          onMessage(TOOL_USE_PROGRESS_DELTA, parsedChunk);
          break;
        case TOOL_USE_DONE:
          onMessage(TOOL_USE_DONE, parsedChunk);
          break;
        default:
          console.warn('Unknown tool usage type:', usageType);
      }
      break;
    default:
      console.warn('Unknown event type:', eventType);
  }
};

const streamReader = async (reader, onMessage) => {
  const decoder = new TextDecoder('utf-8');
  let buffer = '';

  try {
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      buffer += decoder.decode(value, { stream: true });
      let boundary = buffer.indexOf('}{');
      while (boundary !== -1) {
        try {
          processChunk(buffer.slice(0, boundary + 1), onMessage);
        } catch (error) {
          console.error('Error parsing chunk:', error);
          onMessage(MESSAGE_ERRORED, '');
          throw error; // Re-throw to handle it in the outer catch block
        }
        buffer = buffer.slice(boundary + 1);
        boundary = buffer.indexOf('}{');
      }
    }
    if (buffer.length > 0) {
      try {
        processChunk(buffer, onMessage);
      } catch (error) {
        console.error('Error parsing final chunk:', error);
        onMessage(MESSAGE_ERRORED, '');
        throw error;
      }
    }
  } catch (error) {
    console.error('Error in stream reading:', error);
    onMessage(MESSAGE_ERRORED, '');
  } finally {
    onMessage(MESSAGE_DONE, '');
  }
};

export const sendMessage = async (chatid, messageContent, onMessage) => {
  try {
    const response = await apiPost(
      `api/conversations/${chatid}/messages`,
      { content: messageContent },
      true
    );

    if (!response.body) {
      throw new ApiError(response.status, 'No response body found');
    }

    await streamReader(response.body.getReader(), onMessage);
  } catch (error) {
    if (error instanceof ApiError && error.status === 204) {
      console.warn('No content in response, but status indicates success');
    } else {
      handleApiError(error, 'Failed to send message:');
    }
  }
};

export const fetchConversation = async (chatid) => {
  try {
    return await apiGet(`api/conversations/${chatid}`);
  } catch (error) {
    handleApiError(error, 'Failed to fetch messages:');
  }
};

export const createConversation = async (agentId) => {
  try {
    return await apiPost('api/conversations', {
      name: 'Unnamed',
      agent_id: agentId,
    });
  } catch (error) {
    handleApiError(error, 'Failed to create a new conversation:');
  }
};
