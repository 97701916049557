import { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { apiPost } from '../tacktech/api'; // Assuming you have a helper for API calls

const UpdateUsername = () => {
  const { user, fetchUserData } = useAuth();
  const [newUsername, setNewUsername] = useState('');
  const [usernameUpdateMessage, setUsernameUpdateMessage] = useState('');

  const handleUsernameChange = (e) => {
    setNewUsername(e.target.value);
  };

  const handleUsernameUpdate = async () => {
    try {
      await apiPost('api/auth/update-username', { username: newUsername });
      setUsernameUpdateMessage('Username updated successfully.');
      fetchUserData(); // Refresh user data
    } catch (error) {
      console.error('Error updating username:', error);
      setUsernameUpdateMessage('Failed to update username.');
    }
  };

  return (
    <div className="bg-zinc-900 p-6 rounded-lg shadow-lg">
      <h2 className="text-base font-semibold leading-7 text-white mb-4">Profile Settings</h2>
      <div className="flex flex-col space-y-4">
        <input
          type="text"
          placeholder="New Username"
          value={newUsername}
          onChange={handleUsernameChange}
          className="w-full text-white text-md lg:text-sm placeholder:text-gray-400 bg-zinc-900 border-2 border-gray-700 rounded-lg focus:border-gray-700 focus:bg-zinc-950 focus:ring-0"
        />
        <button
          onClick={handleUsernameUpdate}
          className="p-2 bg-amber-400 text-black rounded-md hover:opacity-75"
        >
          Update Username
        </button>
        {usernameUpdateMessage && (
          <p className="text-sm text-white">{usernameUpdateMessage}</p>
        )}
      </div>
    </div>
  );
};

export default UpdateUsername;
