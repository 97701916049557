import React, { useState, useEffect } from 'react';
import { UserCircleIcon } from '@heroicons/react/20/solid';
import Label from './Label';

const ImageUpload = ({ avatarUrl, name, handleAvatarChange }) => {
  const [avatarPreview, setAvatarPreview] = useState(avatarUrl || '');

  // Update avatarPreview if avatarUrl changes
  useEffect(() => {
    setAvatarPreview(avatarUrl || '');
  }, [avatarUrl]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarPreview(reader.result);
      };
      reader.readAsDataURL(file);
      handleAvatarChange(e); // Pass the file to the parent form handler
    }
  };

  return (
    <section className="col-span-full">
      <Label htmlFor={name}>
        Photo
      </Label>
      <div className="mt-2 flex items-center gap-x-3">
        {avatarPreview ? (
          <img src={avatarPreview} alt="Avatar Preview" className="h-12 w-12 rounded-full object-cover" />
        ) : (
          <UserCircleIcon className="h-12 w-12 text-gray-300" aria-hidden="true" />
        )}
        <input
          id={name}
          type="file"
          className="hidden"
          accept="image/*"
          onChange={handleFileChange}
        />
        <button
          type="button"
          className="rounded-md bg-amber-400 px-2.5 py-1.5 text-sm font-semibold text-black shadow-sm ring-1 ring-inset hover:opacity-75"
          onClick={() => document.getElementById(name).click()}
        >
          Change
        </button>
      </div>
      <div className="mt-2 text-sm text-white">
        <p>Images should be less than 2mb and in either jpg, png, webp, or gif format.</p>
        <span id="filename" className="text-gray-500 bg-gray-200 z-50"></span>
      </div>
    </section>
  );
};

export default ImageUpload;