import React from 'react';
import { Link } from 'react-router-dom';
import PageSidebarTemplate from '../templates/PageSidebarTemplate';

export default function SubscriberTerms() {
  return (
    <PageSidebarTemplate>
      <div className="overflow-y-scroll">
        <div
          style={{
            padding: '20px',
            fontFamily: 'Arial, sans-serif',
            lineHeight: '1.8',
            maxWidth: '800px',
            margin: '50px auto',
            color: '#FFFFFF',
            backgroundColor: '#2E2E2E',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          <h1
            style={{
              textAlign: 'center',
              marginBottom: '20px',
              color: '#FFD700',
            }}
          >
            TackTech.ai Subscription Terms
          </h1>

                <h2 style={{ color: '#FFD700', marginTop: '20px' }}>1. Service Description</h2>
                <p>TackTech.ai provides a subscription service offering unlimited messaging, image generation, and unlimited private agents. These features can be utilized within our platform to access various AI-powered services and features.</p>

                <h2 style={{ color: '#FFD700', marginTop: '20px' }}>2. Subscription Plans</h2>
                <p>We offer the following subscription tiers:</p>
                <ul>
                    <li><strong style={{ color: '#FFD700' }}>Free Tier</strong>: Provides limited access to messaging.</li>
                    <li><strong style={{ color: '#FFD700' }}>Monthly Subscription</strong>: $15.00 per month, providing unlimited access to all features.</li>
                    <li><strong style={{ color: '#FFD700' }}>Yearly Subscription</strong>: $144.00 per year (equates to $12.00 per month), providing unlimited access to all features.</li>
                </ul>
                <p>Subscribers can choose to be billed either monthly or annually based on their selected plan.</p>

          <h2 style={{ color: '#FFD700', marginTop: '20px' }}>
            3. Payment and Billing
          </h2>
          <p>
            We accept all payment methods available via Stripe. Your
            subscription will automatically renew at the end of each billing
            cycle unless canceled. Payments are processed securely, and you will
            receive a receipt via email after each successful transaction.
          </p>
          <p>
            If we are unable to process your payment within a specified grace
            period, your subscription will be temporarily suspended until
            payment is successfully processed. We reserve the right to terminate
            your subscription if the payment issue is not resolved within a
            reasonable timeframe.
          </p>

          <h2 style={{ color: '#FFD700', marginTop: '20px' }}>
            4. Billing Cycle and Changes
          </h2>
          <p>
            Your billing cycle corresponds to the date you initially subscribed.
            For example, if you subscribed on the 15th of the month, your
            renewal date will be the 15th of each subsequent month. You can
            change your billing frequency (monthly or annually) through your
            account settings. Any changes to your subscription plan will take
            effect at the start of the next billing cycle.
          </p>

                <h2 style={{ color: '#FFD700', marginTop: '20px' }}>5. Refund Policy</h2>
                <p>All subscriptions are non-refundable. The benefits provided by the subscription do not carry over to the next month and have no cash value. They are non-transferable and can only be used on TackTech.ai.</p>

          <h2 style={{ color: '#FFD700', marginTop: '20px' }}>
            7. Account Management
          </h2>
          <p>
            Subscribers can manage their subscription details, including
            updating payment information or canceling their subscription, via
            their settings page on our platform.
          </p>
          <p>
            <strong>Account Termination:</strong> We reserve the right to
            terminate accounts that violate our terms or engage in misuse of our
            services.
          </p>

          <h2 style={{ color: '#FFD700', marginTop: '20px' }}>
            8. Privacy and Data Use
          </h2>
          <p>
            We handle user/subscriber data in accordance with our{' '}
            <Link style={{ color: '#FFD700' }} to="/privacy">
              Privacy Policy
            </Link>{' '}
            and{' '}
            <Link style={{ color: '#FFD700' }} to="/terms">
              Terms of Use
            </Link>
            . Only necessary third parties receive subscriber data to facilitate
            our services.
          </p>

          <h2 style={{ color: '#FFD700', marginTop: '20px' }}>
            9. Changes to Terms
          </h2>
          <p>
            We may update these terms as necessary. Subscribers will be notified
            of any significant changes via email. Continued use of our services
            after such updates constitutes acceptance of the revised terms.
          </p>



                <h2 style={{ color: '#FFD700', marginTop: '20px' }}>10. Contact Information</h2>
                <p>For any questions or assistance, subscribers can contact us at <a href="mailto:support@tacktech.ai" style={{ color: '#FFD700' }}>support@tacktech.ai</a>. Our support team is here to help with any issues or inquiries you may have.</p>
                              {/*
            <h2 style={{ color: '#FFD700', marginTop: '20px' }}>11. Free Trials</h2>
            <p>We occasionally offer free trials for our subscription plans. If you sign up for a free trial, you will have access to all features of the chosen subscription tier for the duration of the trial period. At the end of the trial period, your subscription will automatically convert to a paid plan unless you cancel before the trial ends. Free trials are limited to one per user and cannot be combined with other offers.</p>
            */}
        </div>
      </div>
    </PageSidebarTemplate>
  );
}
