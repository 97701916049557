import React from 'react';
import { Link } from 'react-router-dom';
import { getAvatarUrl } from '../tacktech/api';
import { card_color } from '../utilities/ColorPalette';
import ConversationCount from './ConversationCount';

function Card({
  id,
  avatar_url,
  name,
  owner,
  chats
}) {

  return (
    <Link to={`/agents/${id}/chat`} className="flex items-center">
      <div
        className={`flex items-center ${card_color} text-white p-4 rounded-lg w-72 h-24 hover:bg-zinc-950 hover:cursor-pointer`}
      >
        <img
          className="h-14 w-14 rounded-full object-cover mr-2"
          src={getAvatarUrl(avatar_url)}
          alt="avatar_image"
        />
        <div className="ml-2 flex flex-col justify-center pt-[0.8rem] w-full">
          <div className="font-semibold leading-tight">{name}</div>
          <div className="text-sm text-gray-400">By @{owner.username}</div>
          <ConversationCount count={chats} />
        </div>
      </div>
    </Link>
  );
}

export default function DiscoveryCardList({ cards, loading }) {
  // Function to chunk an array into smaller arrays of a specified size
  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  // Group the cards into chunks of 2
  const chunkedCards = chunkArray(cards, 2);

  return (
    <div className="flex space-x-4">
      {chunkedCards.length === 0
        ? Array.from({ length: 4 }).map((_, index) => (
          <div
            key={index}
            className={`${card_color} text-white rounded-lg animate-pulse flex flex-col space-y-4 min-w-max`}
          >
            <div className="flex items-center p-4">
              <div className="h-10 w-10 bg-gray-700 rounded-full"></div>
              <div className="flex flex-col w-2/3 m-2">
                <div className="h-2 bg-gray-700 rounded mb-2"></div>
                <div className="h-2 w-3/4 bg-gray-700 rounded mb-2" />
                <div className="h-2 w-3/4 bg-gray-700 rounded" />
              </div>
            </div>
          </div>
        ))
        : chunkedCards.map((chunk, index) => (
          <div key={index} className="flex flex-col space-y-4 min-w-max">
            {chunk.map((agent) => (
              <Card key={agent.id} {...agent} />
            ))}
          </div>
        ))}
    </div>
  );
}
