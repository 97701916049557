import { useEffect, useRef, useState } from 'react';
import { getAvatarUrl } from '../tacktech/api';
import {
  EllipsisHorizontalIcon,
  PencilIcon,
  TrashIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import { useSidebarCollapse } from '../contexts/SidebarCollapseContext';
import {
  updateConversationName,
  useConversationsContext,
  deleteConversation
} from '../contexts/ConversationsContext';
import { useParams, useNavigate } from 'react-router-dom';

const DropdownMenu = ({ onRenameClick, onDelete }) => (
  <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-md shadow-lg z-50">
    <button
      onClick={onRenameClick}
      className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
    >
      <PencilIcon className="h-5 w-5 mr-2" />
      Rename
    </button>
    <button
      onClick={onDelete}
      className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
    >
      <TrashIcon className="h-5 w-5 mr-2" />
      Delete
    </button>
  </div>
);

const ConversationListItem = ({ chat, handleDelete }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(chat.name);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const { setIsCollapsed } = useSidebarCollapse();
  const { dispatch } = useConversationsContext();
  const { chatid } = useParams();
  const navigate = useNavigate();

  const handleRenameClick = (e) => {
    e.stopPropagation();
    setIsEditing(true);
    setIsDropdownOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      doRename(event, chat, newName);
      setIsEditing(false);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  function handleSidebarStateOnClick() {
    if (window.matchMedia('screen and (max-width: 768px)').matches) {
      setIsCollapsed(true);
    }
  }

  async function doDelete(event, chat) {
    event.preventDefault();
    setIsDropdownOpen(false);
    setIsLoading(true);
    const didDelete = await deleteConversation(dispatch, chat);
    if (Number(chatid) === chat.id && didDelete) {
      navigate('..', { relative: true });
    }
    if (!didDelete) setIsError(true);
    setIsLoading(false);
  }

  async function doRename(event, chat) {
    event.preventDefault();
    setIsLoading(true);
    const didRename = await updateConversationName(
      dispatch,
      chat,
      newName || 'Unnamed'
    );
    setIsLoading(false);
    if (!didRename) setIsError(true);
  }



  return (
    <li key={chat.id} className={`flex justify-between items-center hover:bg-zinc-600 relative pr-2 rounded-md ${Number(chatid) === chat.id && "bg-zinc-700"}`}>
      <Link
        onClick={handleSidebarStateOnClick}
        to={`/agents/${chat.agent_id}/chat/${chat.id}`}
        rel="preconnect"
        className={` hover:text-white group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold flex-grow items-center justify-between ${Number(chatid) === chat.id ? "text-white" : "text-gray-400"}`}
        draggable={false}

      >
        <span className="relative inline-block">
          <img
            className="h-10 w-10 rounded-full object-cover"
            src={getAvatarUrl(chat.avatar_url)}
            alt="AI avatar"
            draggable={false}
          />
        </span>
        {isEditing ? (
          <input
            ref={inputRef}
            type="text"
            value={newName}
            onClick={(e) => e.preventDefault()}
            onChange={(e) => setNewName(e.target.value)}
            onKeyDown={handleKeyDown}
            className="absolute text-gray-800 bg-white rounded-md px-3 py-2 mx-0 flex-grow z-40 focus:ring-2 focus:ring-violet-500"
          />
        ) : (
          <span className="truncate grow text-left mx-1">{chat.name}</span>
        )}
        {isLoading && (
          <div className="loader border-t-transparent border-solid animate-spin border-yellow-500 rounded-full border-4 h-5 w-5"></div>
        )}
        {isError && <XCircleIcon className="h-5 w-5 text-red-500" />}
      </Link>
      <button
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="text-gray-400 hover:text-white p-1"
      >
        <EllipsisHorizontalIcon className="h-5 w-5" />
      </button>
      <div ref={dropdownRef}>
        {isDropdownOpen && (
          <DropdownMenu
            onRenameClick={(e) => handleRenameClick(e)}
            onDelete={(e) => doDelete(e, chat)}
          />
        )}
      </div>
    </li>
  );
};

export default ConversationListItem;
