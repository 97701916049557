import ReactGA from 'react-ga4';

const TRACKING_ID = 'G-RXSJXX589R'; 

export const initGA = () => {
  ReactGA.initialize(TRACKING_ID);
};

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname + window.location.search });
};

export const logEvent = (category, action, label = '', value = 0) => {
  ReactGA.event({
    category,
    action,
    label,
    value
  });
};

// Example for logging an 'Upgrade Plan' link click
export const sidebarUpgradePlan = () => {
  ReactGA.event("sidebar_upgrade_plan", {
    category: 'Navigation', // Use a descriptive category for navigation or link clicks
    action: 'click',
    label: 'Upgrade Plan', // Label to identify the specific link
    event_name: 'sidebar_upgrade_plan' // Custom event name
  });
};

export const quotaExceededUpgrade = () => {
  ReactGA.event("quota_exceeded_upgrade", {
    category: 'Navigation',
    action: 'click',
    label: 'Upgrade Plan',
    event_name: 'quota_exceeded_upgrade'
  });
};

export const quotaExceededRegister = () => {
  ReactGA.event("quota_exceeded_register", {
    category: 'Navigation',
    action: 'click',
    label: 'Register User',
    event_name: 'quota_exceeded_register'
  });
};

export const settingsUpgradePlan = () => {
  ReactGA.event("settings_upgrade_plan", {
    category: 'Navigation',
    action: 'click',
    label: 'Upgrade Plan',
    event_name: 'settings_upgrade_plan'
  });
};

export const resetPassword = () => {
  ReactGA.event("reset_password", {
    category: 'Account Management',
    action: 'click',
    label: 'Email',
    event_name: "reset_password"
  });
};

export const accountSignUp = () => {
  ReactGA.event("account_signup", {
    category: 'Account Management',
    action: 'click',
    label: 'Email',
    event_name: 'account_signup'
  });
};

export const agentCreation = () => {
  ReactGA.event('agent_creation', {
    category: 'Usage',
    action: 'click',
    label: 'Useage',
    event_name: 'agent_creation'
  });
};

export const subscribeEvent = () => {
  ReactGA.event('subscribe_event', {
    category: 'Usage',
    action: 'click',
    label: 'Usage',
    event_name: 'subscribe_event'
  });
};