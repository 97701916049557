import { createContext, useState, useContext, useEffect } from "react";

const SidebarContext = createContext();

function SidebarContextProvider({ children }) {
	const isSmallerThanLargeScreen = window.innerWidth < 1920;
	const [isCollapsed, setIsCollapsed] = useState(() => {
		const savedState = localStorage.getItem('sidebarContextState');
		if (isSmallerThanLargeScreen && !savedState) return true;
		return savedState ? JSON.parse(savedState) : false;
	});

	useEffect(() => {
		localStorage.setItem('sidebarContextState', isCollapsed);
	}, [isCollapsed]);

	const value = { isCollapsed, setIsCollapsed }

	return (
		<SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
	);
}

function useSidebarCollapse() {
	const context = useContext(SidebarContext);
	if (context === undefined) {
		throw new Error(
			"useSidebarCollapse must be used within a SidebarContextProvider"
		);
	}
	return context;
}

export { SidebarContextProvider, useSidebarCollapse };
