import React from 'react';
import PropTypes from 'prop-types';
import { quotaExceededUpgrade, quotaExceededRegister } from '../utilities/analytics';





const UpsellComponent = ({ isLoggedIn, onRegisterOrLogin, onUpgrade, subtype, content }) => {
  const msg = content && subtype === "tool_restriction" ? content : isLoggedIn ? "You've reached your daily message limit. Upgrade to a subscription plan for unlimited messages!" : "You've reached your daily message limit. Register or log in to get more messages!";

  return (
    <div className="p-4 rounded-md shadow-md bg-zinc-900 text-center"> {/* Changed container to zinc-900 */}
      <p className="mb-4 text-lg font-semibold text-white"> {/* Ensured text color is white */}
        {msg}
      </p>
      <button
        className="bg-amber-400 text-black px-4 py-2 rounded-md hover:opacity-75 focus:outline-none" // Updated button styles
        onClick={() => {
          if (isLoggedIn) {
            quotaExceededUpgrade();
            onUpgrade();
          } else {
            quotaExceededRegister();
            onRegisterOrLogin();
          }
        }}
      >
        {isLoggedIn ? "Upgrade Now" : "Register or Login"}
      </button>
    </div>
  );
};

UpsellComponent.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired, // Determine if the user is logged in
  onRegisterOrLogin: PropTypes.func.isRequired, // Function to handle register/login action
  onUpgrade: PropTypes.func.isRequired, // Function to handle upgrade action
};

export default UpsellComponent;
