import { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ChatMessage from "../components/ChatMessage";
import ChatInput from "../components/ConversationChatInput";
import { ChevronDoubleDownIcon } from "@heroicons/react/20/solid";
import UpsellComponent from "../components/UpsellComponent";
import LoginModal from "../components/LoginModal";
import SubscriptionsModal from "../components/SubscriptionsModal";
import { useAuth } from "../contexts/AuthContext";
import ChatAgentGreeter from "../components/ChatAgentGreeter";
import ChatAgentUtilityBar from "../components/ChatAgentUtilityBar"
import { useInitialMessage } from "../contexts/InitialMessageContext";
import { AgentContextProvider } from "../contexts/AgentContext";
import { sendMessage, fetchConversation, createConversation } from "../tacktech/chat";
import { ActionTypes, useConversationsContext } from "../contexts/ConversationsContext";
import PageSidebarTemplate from "../templates/PageSidebarTemplate";
import {
  TOKEN_UPSELL_MESSAGE,
  ERROR_MESSAGE,
  USER_MESSAGE,
  AGENT_MESSAGE,
  TOOL_UPSELL_MESSAGE
} from "../tacktech/chat/constants";
import {
  ADD_MESSAGE,
  UPDATE_LAST_LOADING_MESSAGE,
  SET_MESSAGES,
  MESSAGE_PROGRESS_DELTA,
  MESSAGE_DONE,
  DELETE_UPSELL_MESSAGES,
  TOOL_USE_DONE,
  TOOL_USE_STARTING,
  TOOL_USE_PROGRESS_DELTA,
  TOOL_USE_RESTRICTED,
  ERROR
} from "../tacktech/chat/actions";
import { useMessagesContext } from "../contexts/MessagesContext";
import { apiGet } from "../tacktech/api";
import ToolBlock from "../components/tool/ToolBlock";


const Chat = () => {
  const { id, chatid } = useParams();
  const { userLoading, user } = useAuth();
  const { initialMessage, setInitialMessage } = useInitialMessage();
  const { state, dispatch: dispatchMessage } = useMessagesContext();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isSubscriptionsModalOpen, setIsSubscriptionsModalOpen] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const chatContainerRef = useRef(null);
  const messagesEndRef = useRef(null);
  const initialMessageSentRef = useRef(false);
  const isLoggedIn = !userLoading && user;
  const { dispatch: dispatchConversation } = useConversationsContext();
  const navigate = useNavigate();
  const [hasFirstMessage, setHasFirstMessage] = useState(false);
  const [initializedChat, setInitializedChat] = useState(0);
  const [canMsg, setCanMsg] = useState(true);

  const updateLastLoadingMessage = useCallback((chatId, newMessage) => {
    dispatchMessage({ type: UPDATE_LAST_LOADING_MESSAGE, conversationId: chatId, payload: newMessage });
  }, [dispatchMessage]);

  const handleMessageResponseChunk = useCallback((eventType, chunk, chatid) => {
    if (eventType === MESSAGE_DONE) {
      dispatchMessage({ type: MESSAGE_DONE, conversationId: chatid });
      setCanMsg(true);
    } else if (eventType === TOOL_USE_RESTRICTED) {
      dispatchMessage({
        type: ADD_MESSAGE, conversationId: chatid, payload:
          TOOL_UPSELL_MESSAGE
      })
    }
    else if (eventType === TOOL_USE_STARTING) {
      dispatchMessage({ type: eventType, conversationId: chatid, payload: chunk });
    }
    else if (eventType === TOOL_USE_DONE) {
      dispatchMessage({ type: eventType, conversationId: chatid, payload: chunk });
    }
    else if (eventType === TOOL_USE_PROGRESS_DELTA) {
      dispatchMessage({ type: eventType, conversationId: chatid, payload: chunk });
    }
    else {
      dispatchMessage({
        type: MESSAGE_PROGRESS_DELTA,
        conversationId: chatid,
        payload: {
          content: chunk,
          isLoading: true,
          done: false,
        },
      });
    }
  }, [dispatchMessage]);

  const handleMessageQuotaExceeded = useCallback((chatId) => {
    updateLastLoadingMessage(chatId, TOKEN_UPSELL_MESSAGE);
  }, [updateLastLoadingMessage]);

  const handleMessageError = useCallback((error) => {
    updateLastLoadingMessage(chatid, ERROR_MESSAGE);
  }, [updateLastLoadingMessage, chatid]);

  const initializeChat = useCallback(async () => {
    if (chatid) {
      setInitializedChat(chatid);
      try {
        const response = await fetchConversation(chatid);
        dispatchConversation({ type: ActionTypes.UPDATE_CONVERSATION, payload: response });
        if (!hasFirstMessage) {
          dispatchMessage({ type: SET_MESSAGES, conversationId: chatid, payload: response.messages });
        }
      } catch (error) {
        if (error.status === 403) {
          dispatchConversation({ type: ERROR, payload: error })
          dispatchMessage({ type: ERROR, conversationId: chatid, payload: error });
        } else {
          dispatchMessage({ type: ERROR, conversationId: chatid, payload: error.message });
        }
      }
    }
  }, [chatid, dispatchConversation, dispatchMessage, hasFirstMessage]);

  useEffect(() => {
    setShowScrollButton(false);
    initializeChat();
  }, [chatid, initializeChat, user?.id]);

  const createNewConversation = useCallback(async (messageContent) => {
    try {
      const response = await createConversation(id);
      const newChatId = response.id;
      navigate(`./${newChatId}`, { relative: "path" });
      const agentResponse = await apiGet(`api/agents/${id}`);
      dispatchConversation({ type: ActionTypes.ADD_CONVERSATION, payload: { ...response, avatar_url: agentResponse.avatar_url } });
      dispatchMessage({ type: ADD_MESSAGE, conversationId: newChatId, payload: { ...USER_MESSAGE, content: messageContent } });
      dispatchMessage({ type: ADD_MESSAGE, conversationId: newChatId, payload: AGENT_MESSAGE });
      return newChatId;
    } catch (error) {
      console.error("Error creating conversation:", error);
      throw error;
    }
  }, [id, navigate, dispatchConversation, dispatchMessage]);

  const sendMessageToChat = useCallback(async (chatId, messageContent) => {
    try {
      await sendMessage(chatId, messageContent, (responseChunk, eventType) => {
        handleMessageResponseChunk(responseChunk, eventType, chatId);
      });
    } catch (error) {
      if (error.status === 429) {
        handleMessageQuotaExceeded(chatId);
      } else {
        handleMessageError(error);
      }
    }
  }, [handleMessageResponseChunk, handleMessageQuotaExceeded, handleMessageError]);

  const handleSendMessage = useCallback(async (messageContent) => {
    if (canMsg) {
      setCanMsg(false);
      setHasFirstMessage(true);
      let chatId = chatid;
      if (!chatid) {
        chatId = await createNewConversation(messageContent);
      } else {
        dispatchMessage({ type: ADD_MESSAGE, conversationId: chatId, payload: { ...USER_MESSAGE, content: messageContent } });
        dispatchMessage({ type: ADD_MESSAGE, conversationId: chatId, payload: AGENT_MESSAGE });
      }
      await sendMessageToChat(chatId, messageContent);
    }
  }, [chatid, createNewConversation, sendMessageToChat, dispatchMessage, canMsg]);

  useEffect(() => {
    const maybeSendInitialMessage = async () => {
      if (initialMessage && !initialMessageSentRef.current) {
        initialMessageSentRef.current = true;
        setInitialMessage("");
        await handleSendMessage(initialMessage);
      }
    };

    maybeSendInitialMessage();
  }, [initialMessage, state.conversationData, chatid, handleSendMessage, setInitialMessage]);


  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [state.conversationData, chatid]);

  useEffect(() => {
    if (user && user?.quota_exceeded === false) {
      dispatchMessage({ type: DELETE_UPSELL_MESSAGES, conversationId: chatid, payload: { subtype: 'token_exceeded' } });
    }
    if (user && user?.subscription !== null) {
      dispatchMessage({ type: DELETE_UPSELL_MESSAGES, conversationId: chatid, payload: { subtype: 'tool_restriction' } });
    }
    setCanMsg(true);
  }, [user, dispatchMessage, chatid]);

  const handleScrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      setShowScrollButton(false);
    }
  };

  const handleBtnOnScroll = () => {
    const height = chatContainerRef.current.clientHeight;
    const offset = chatContainerRef.current.scrollHeight - height;
    const scrollPosition = chatContainerRef.current.scrollTop;
    const currentScrollHeight = height - scrollPosition;
    if (height - currentScrollHeight + 10 >= offset) {
      setShowScrollButton(false);
    } else if (!showScrollButton) {
      setShowScrollButton(true);
    }
  };

  const handleOpenLoginModal = () => {
    setIsLoginModalOpen(true);
  };

  const handleCloseLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  const handleOpenSubscriptionsModal = () => {
    setIsSubscriptionsModalOpen(true);
  };

  const handleCloseSubscriptionsModal = () => {
    setIsSubscriptionsModalOpen(false);
  };

  return (
    <AgentContextProvider>
      <PageSidebarTemplate NavbarContent={<ChatAgentUtilityBar />}>
        <div className="h-full w-full flex flex-col">
          <div ref={chatContainerRef} onScroll={handleBtnOnScroll} className="relative flex-1 mb-[6rem] overflow-y-auto px-1 overflow-x-clip">
            <div className="flex flex-col max-w-4xl mx-auto dark:text-slate-100 sm:text-base">
              <ChatAgentGreeter handleSuggestionClick={handleSendMessage} />
              {chatid && state.conversationData[chatid]?.loading ? (
                <div className="text-center">Loading...</div>
              ) : (
                <>
                  {state.conversationData[chatid]?.messages?.map((message, index) => (
                    <div key={index} className="mb-4">
                      {message.type === "upsell" ? (
                        <UpsellComponent
                          content={message.content}
                          subtype={message.subtype}
                          isLoggedIn={isLoggedIn}
                          onRegisterOrLogin={handleOpenLoginModal}
                          onUpgrade={handleOpenSubscriptionsModal}
                        />
                      ) :
                        message.type === "tool.usage" ? (
                          <ToolBlock
                            message={message}
                            tool={message.tool}
                            isLoading={message.isLoading}
                            isError={message.error}
                            cancelHandler={() => null} />
                        ) :
                          (
                            <ChatMessage
                              role={message.role}
                              message={message.content}
                              colorLight={message.colorLight}
                              avatar_url={""}
                              isLoading={message.isLoading}
                              colorDark={message.colorDark}
                              errorMessage={message.errorMessage}
                              done={message.done}
                            />
                          )}
                    </div>
                  ))}
                  <div ref={messagesEndRef} />
                </>
              )}
            </div>
            <button
              onClick={handleScrollToBottom}
              className={`z-40 fixed bottom-16 xl:bottom-12 xl:right-[calc(50%-1.25rem)] right-0 flex justify-center items-center bg-zinc-900 text-amber-400 mx-1 px-1 mb-0 rounded-full w-8 h-8 opacity-90 border-gray-700 border-2 md:border-0  ${!showScrollButton ? "hidden" : "block"} `}
            >
              <ChevronDoubleDownIcon className="size-5" />
            </button>
          </div>
          <div className="sticky bottom-1 w-full">
            <div className="flex justify-center">
              <ChatInput onSendMessage={handleSendMessage} canSend={canMsg} />
            </div>
          </div>
          <LoginModal isOpen={isLoginModalOpen} onClose={handleCloseLoginModal} />
          <SubscriptionsModal isOpen={isSubscriptionsModalOpen} onClose={handleCloseSubscriptionsModal} />
        </div>
      </PageSidebarTemplate>
    </AgentContextProvider>
  );
};

export default Chat;
