import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import PageSidebarTemplate from '../templates/PageSidebarTemplate';
import AdminCheck from '../components/AdminCheck';

export default function AdminPage() {
  const { user } = useAuth();

  return (
    <PageSidebarTemplate>
      <AdminCheck>
      </AdminCheck>
    </PageSidebarTemplate>
  );
}
