import React from 'react';
import { FaTwitter, FaInstagram, FaFacebook } from 'react-icons/fa';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';
import PageNoSidebarTemplate from '../templates/PageNoSidebarTemplate';
import Logo from '../components/Logo';

const socialLinks = [
  {
    name: 'Twitter',
    url: 'https://x.com/TackTechAI',
    icon: FaTwitter,
  },
  {
    name: 'Facebook',
    url: 'https://www.facebook.com/profile.php?id=61562208537703',
    icon: FaFacebook,
  },
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/tacktechai/',
    icon: FaInstagram,
  },
];

const SocialLinks = () => {
  return (
    <section className="py-12 bg-zinc-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl font-extrabold text-amber-400 mb-6">
          Connect with Us
        </h2>
        <p className="text-lg text-white mb-8">
          Stay connected and join our growing community on social media. Follow
          us for the latest updates, discussions, and more.
        </p>
        <div className="flex justify-center space-x-8">
          {socialLinks.map((link) => (
            <a
              key={link.name}
              href={link.url}
              className="text-amber-400 hover:opacity-75 transition duration-300"
              target="_blank"
              rel="noopener noreferrer"
              aria-label={link.name}
            >
              <link.icon className="h-10 w-10" />
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default function About() {
  return (
    <PageNoSidebarTemplate NavbarContent={<Logo />}>
      <div className="bg-zinc-800 overflow-y-scroll">
        <Helmet>
          <title>TackTech - About</title>
          <meta
            name="description"
            content={
              'Explore and interact with a variety of AI chatbots and assistants tailored to meet your needs.'
            }
          />
          <meta
            name="keywords"
            content="AI, chatbots, assistant, support, interactive, machine learning, artificial intelligence"
          />

          {/* Open Graph Meta Tags */}
          <meta
            property="og:title"
            content={'TackTech - AI Chatbots and Assistants'}
          />
          <meta
            property="og:description"
            content={
              'Explore and interact with a variety of AI chatbots and assistants tailored to meet your needs.'
            }
          />
          <meta property="og:image" content={'logo.jpg'} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />

          {/* Twitter Card Meta Tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content={'TackTech - AI Chatbots and Assistants'}
          />
          <meta
            name="twitter:description"
            content={
              'Explore and interact with a variety of AI chatbots and assistants tailored to meet your needs.'
            }
          />
          <meta name="twitter:image" content={'logo.jpg'} />

          {/* Additional Meta Tags */}
          <meta name="author" content="TackTech Inc." />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />

          {/* Favicon */}
          <link rel="icon" href="favicon.ico" type="image/x-icon" />
        </Helmet>
        <main className="isolate mx-auto">
          {/* Hero section */}
          <div className="relative isolate -z-10">
            <svg
              className="absolute inset-x-0 top-0 -z-10 h-[48rem] )]"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                  width={200}
                  height={200}
                  x="50%"
                  y={-1}
                  patternUnits="userSpaceOnUse"
                >
                  <path d="M.5 200V.5H200" fill="none" />
                </pattern>
              </defs>

              <rect
                width="100%"
                height="100%"
                strokeWidth={0}
                fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
              />
            </svg>
            <div className="absolute left-1/2 right-0 top-0 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48" aria-hidden="true">
            </div>
            <div className="overflow-hidden">
              <div className="mx-auto max-w-7xl px-6 pb-24 pt-24 lg:px-8 lg:pt-24 text-center">
                <div className="mx-auto max-w-3xl gap-x-14 lg:mx-0 lg:max-w-none lg:items-center">
                  <div className="w-full max-w-xl lg:shrink-0 xl:max-w-3xl mx-auto">
                    <h1 className="text-4xl font-bold tracking-tight text-amber-400 sm:text-6xl">
                      Empowering You with Intelligent Assistants
                    </h1>
                    <p className="relative mt-6 text-lg leading-8 text-white sm:max-w-md lg:max-w-none max-w-3xl mx-auto">
                      TackTech is dedicated to bridging the gap between human capabilities and machine intelligence. We offer a range of AI chatbots and assistants designed to simplify your daily tasks, provide technical support, and much more. Our mission is to make advanced AI technology accessible to everyone, enhancing productivity and making life easier. With TackTech, you can create custom AI to personalize your experience and see what others in the TackTech community have made, allowing you to benefit from shared knowledge and innovation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="py-12 bg-zinc-800">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
              <h2 className="text-3xl font-extrabold text-amber-400 mb-6">
                Why choose TackTech?
              </h2>
              <p className="text-lg leading-8 text-white mb-8 max-w-3xl mx-auto">
                TackTech provides multiple cutting-edge large language models (LLMs). As these models improve or new ones surface, we adapt and offer them to our users. This ensures that the AI agents you're using are always utilizing the most suitable and up-to-date LLM for their purpose. Our commitment to innovation means you always have access to the best AI technology available.
              </p>
              <p className="text-lg leading-8 text-white mb-8 max-w-3xl mx-auto">
                We offer you a highly customizable experience, allowing you to create agents with unique personalities and styles tailored to your preferences. You can adjust their behavior, tone, and functionality to suit specific tasks or personal inclinations. This flexibility empowers you to craft AI agents that not only meet your practical needs but also align with your creative vision and individuality.
              </p>
            </div>
          </section>
          <section className="py-12 bg-zinc-800">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
              <h2 className="text-3xl font-extrabold text-amber-400 mb-6">
                Explore Our AI Agents
              </h2>
              <p className="text-lg leading-8 text-white mb-8 max-w-3xl mx-auto">
                Our AI agents are designed to assist you in a variety of tasks, from technical support to travel planning. Explore our range of agents and find the one that best suits your needs. If you don't find one you like, sign up and customize your own to perfectly match your preferences.
              </p>
              <a href="https://tacktech.ai" className="inline-block px-6 py-3 text-xl text-zinc-800 font-bold bg-amber-400 hover:bg-amber-500 rounded-md transition duration-300">
                View Agents
              </a>
            </div>
          </section>
          <section className="py-12 bg-zinc-800">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
              <SocialLinks />
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </PageNoSidebarTemplate>
  );
}
