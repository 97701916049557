import { ReactNode, useState } from 'react';
import LoginModal from '../components/LoginModal';
import { NavbarTemplate } from './NavbarTemplate';

type PageNoSidebarTemplateProps = {
  children: ReactNode;
  NavbarContent: JSX.Element;
};

export default function PageNoSidebarTemplate({
  children,
  NavbarContent,
}: PageNoSidebarTemplateProps) {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);

  const handleOpenLoginModal = () => setIsLoginModalOpen(true);
  const handleCloseLoginModal = () => setIsLoginModalOpen(false);

  const handleOpenSignupModal = () => setIsSignupModalOpen(true);
  const handleCloseSignupModal = () => setIsSignupModalOpen(false);

  return (
    <>
      <div className="relative inset-0 flex flex-col justify-center w-screen h-full">
        <main className="flex-1 flex flex-col h-full w-full">
          <NavbarTemplate
            onLoginClick={handleOpenLoginModal}
            onSignupClick={handleOpenSignupModal}
            Content={NavbarContent}
          />
          {children}
        </main>
      </div>
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={handleCloseLoginModal}
        initialFormType="login"
      />
      <LoginModal
        isOpen={isSignupModalOpen}
        onClose={handleCloseSignupModal}
        initialFormType="signup"
      />
    </>
  );
}
