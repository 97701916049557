import React from "react";
import FormSection from "../FormSection";
import RadioInput from "../RadioInput";

const AccessSettings = ({ formData, handleAccessChange }) => {
    return (
      <FormSection
        title="Access Settings"
        description="Control how people can access your AI agent."
      >
        <div className="mt-4 space-y-4">
          <RadioInput
            label="Public"
            value="public"
            checked={formData.visibility === 'public'}
            onChange={handleAccessChange}
            description="Everyone can see and talk to the agent."
          />
          <RadioInput
            label="Unlisted"
            value="unlisted"
            checked={formData.visibility === 'unlisted'}
            onChange={handleAccessChange}
            description="Only people with a link can talk to the agent."
          />
          <RadioInput
            label="Private"
            value="private"
            checked={formData.visibility === 'private'}
            onChange={handleAccessChange}
            description="Only you can talk to the agent."
          />
        </div>
      </FormSection>
    );
};

export default AccessSettings;