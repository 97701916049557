import React, { useState, useEffect, useRef, useCallback } from 'react';
import { agentList } from '../tacktech/api';
import DiscoveryCardList from './DiscoveryCardList';
import { useAuth } from '../contexts/AuthContext';

export default function DiscoveryAgentGrid() {
  const auth = useAuth();

  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [limit] = useState(10); // Adjust as needed
  const [skip, setSkip] = useState(0);
  const scrollContainerRef = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);

  const fetchCards = useCallback(async () => {
    setLoading(true);
    try {
      const data = await agentList({ type: "selected", name: "suggestionlist", limit, skip });
      const agents = data.agents;
      setCards(prevCards => (skip === 0 ? agents : [...prevCards, ...agents]));
    } catch (error) {
      console.error('Error fetching agent list:', error);
    } finally {
      setLoading(false);
    }
  }, [limit, skip]);

  useEffect(() => {
    if (auth.sessionID) {
      fetchCards();
    }
  }, [auth.sessionID, skip, fetchCards]);

  const handleScroll = () => {
    if (!scrollContainerRef.current) return;
    const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
    setShowLeftButton(scrollLeft > 0);
    setShowRightButton(scrollLeft < scrollWidth - clientWidth);
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -500, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 500, behavior: 'smooth' });
      setSkip(prevSkip => prevSkip + limit);
    }
  };

  useEffect(() => {
    handleScroll(); // Check scroll position on load
  }, [cards]);

  // Function to chunk an array into smaller arrays of a specified size
  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  // Group the cards into chunks of 6
  const chunkedCards = chunkArray(cards, 6);

  return (
    <div className="flex-auto max-w-6xl mx-auto">
      <h1 className="text-xl md:text-2xl font-semibold text-gray-200 my-2">Discover</h1>

      <div className="relative">
        {showLeftButton && (
          <button
            className="absolute left-0 top-0 bottom-0 h-full bg-zinc-950 text-white p-1 md:p-4 rounded-r-md opacity-40 md:opacity-80 hover:opacity-85 transition-opacity flex items-center justify-center z-10"
            onClick={scrollLeft}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
            </svg>
          </button>
        )}
        <div
          className="overflow-x-auto py-1 scrollbar-hide"
          ref={scrollContainerRef}
          onScroll={handleScroll}
        >
          <div className="inline-flex space-x-4">
            {chunkedCards.map((chunk, index) => (
              <div key={index} className="min-w-max">
                <DiscoveryCardList cards={chunk} loading={loading} />
              </div>
            ))}
          </div>
        </div>
        {showRightButton && (
          <button
            className="absolute right-0 top-0 bottom-0 h-full bg-zinc-950 opacity-40 md:opacity-80 text-white p-1 md:p-4 rounded-l-md hover:opacity-85 transition-opacity flex items-center justify-center z-10"
            onClick={scrollRight}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
}