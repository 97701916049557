import Label from './Label';
import ErrorMessage from './ErrorMessage';

const Input = ({ id, type, value = '', onChange, placeholder, autoComplete, maxLength, isRequired = false, minLength = 0, }) => {

  return (
    <div className="relative w-full">
      <input
        id={id}
        type={type}
        autoComplete={autoComplete}
        className="block w-full rounded-md border-0 py-1.5 text-gray-100 bg-zinc-900 ring-1 ring-inset ring-gray-700 placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:outline-none focus:ring-2 focus:ring-amber-500"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e)}
        required={isRequired}
        minLength={minLength}
        maxLength={maxLength}
      />
      {
        maxLength && (
          <div className="absolute right-0 text-gray-400 text-xs mt-1">
            {value.length}/{maxLength}
          </div>
        )
      }
    </div>

  );
};


// WARNING: errorMessage can be both type string[] && string

const InputField = ({ label, id, type, value, onChange, placeholder, autoComplete, errorMessage, maxLength, minLength = 0, isRequired = false }) => {

  return (
    <div >
      <Label htmlFor={id}>{label}</Label>
      <div className="rounded-md">
        <Input
          id={id}
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          autoComplete={autoComplete}
          minLength={minLength}
          maxLength={maxLength}
          isRequired={isRequired}
        />
      </div>
      <ErrorMessage message={errorMessage} />
    </div >
  )
};

export default InputField;