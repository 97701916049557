export const TOKEN_UPSELL_MESSAGE = {
  type: 'upsell',
  subtype: 'token_exceeded',
  message: '',
  avatar: '',
  content: '',
  colorLight: 'bg-yellow-100',
  colorDark: 'dark:bg-yellow-900',
  isLoading: false,
};

export const TOOL_UPSELL_MESSAGE = {
  type: 'upsell',
  subtype: 'tool_restriction',
  content:
    'Tool usage features are reserved for subscribed users. Subscribe to generate images and more!',
  isLoading: false,
};

export const ERROR_MESSAGE = {
  type: 'error',
  avatar: '',
  content: 'Failed to fetch the response from the server.',
  colorLight: 'bg-slate-100',
  colorDark: 'dark:bg-zinc-800',
  isLoading: false,
};

export const AGENT_MESSAGE = {
  type: 'agent',
  avatar: '',
  content: '',
  colorLight: 'bg-slate-100',
  colorDark: 'dark:bg-zinc-800',
  isLoading: true,
  done: false,
};

export const USER_MESSAGE = {
  role: 'user',
  avatar: '',
  colorLight: '',
  colorDark: 'bg-zinc-800',
};
