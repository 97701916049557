import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/20/solid';
import { useSidebarCollapse } from '../../contexts/SidebarCollapseContext';
import { useAuth } from '../../contexts/AuthContext';

const CreateAgentButton = ({ onLogin }) => {
  const { setIsCollapsed } = useSidebarCollapse();
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleButtonClick = () => {
    if (!user) {
      onLogin(); // Open login modal if user is not logged in
    } else {
      if (window.matchMedia('screen and (max-width: 768px)').matches) {
        setIsCollapsed(true);
      }
      navigate('/agents/create', { state: { from: window.location.pathname } });
    }
  };

  return (
    <button
      onClick={handleButtonClick}
      className="flex items-center justify-center px-3 py-2 rounded text-black font-bold text-xs bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700 w-full"
    >
      <PlusIcon className="h-4 w-4" />
      <span className="mx-2 truncate">{user ? 'Create Agent' : 'Login to create agents'}</span>
    </button>
  );
};

export default CreateAgentButton;
