import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import LandingPage from './pages/Landing';
import TermsOfService from './pages/Terms';
import PrivacyPolicy from './pages/Privacy';
import { useAuth } from './contexts/AuthContext';
import { initApi } from './tacktech/api';
import WrongPage from './pages/WrongPage';
import NewPassword from './pages/NewPassword';
import Subscription from './pages/Subscriptions';
import Chat from './pages/Chat';
import Agent from './pages/Agent';
import About from './pages/About';
import Profile from './pages/Profile';
import Forbidden from './pages/Forbidden';
import SubscriberTerms from './pages/SubscriberTerms';
import { background } from './utilities/ColorPalette';
import { initGA, logPageView } from './utilities/analytics';
import Settings from './pages/Settings';
import { UserProvider } from './contexts/UserContext';
import { InitialMessageProvider } from './contexts/InitialMessageContext';
import ConversationProvider from './contexts/MessagesContext';
import { ConversationsContextProvider } from './contexts/ConversationsContext';
import OAuthRedirect from './pages/OAuthSignin';
import AdminPage from './pages/Admin';

const App = () => {
  const auth = useAuth();
  const [isApiInitialized, setIsApiInitialized] = useState(false);

  const location = useLocation();

  useEffect(() => {
    initGA();
  }, []);

  useEffect(() => {
    logPageView();
  }, [location]);

  useEffect(() => {
    if (auth.sessionID) {
      console.info('Initializing API with session ID', auth.sessionID);
      initApi(() => auth.sessionID, auth.renewSession);
      setIsApiInitialized(true);
    } else {
      console.info('No session found or session ID not available yet');
      setIsApiInitialized(true);
    }
  }, [auth.sessionID, auth.renewSession]);

  if (!isApiInitialized) {
    return <div>Loading...</div>;
  }

  return (
    <ConversationsContextProvider>
      <div
        className={`${background} absolute z-20 inset-0 flex overflow-y-clip flex-col bg-zinc-800`}
      >
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/newpassword" element={<NewPassword />} />
          <Route path="/agents/create" element={<Agent />} />
          <Route path="/agents/:id/chat/:chatid" element={<Chat />} />
          <Route path="/agents/:id/chat" element={<Chat />} />
          <Route path="/agents/:id" element={<Agent />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<WrongPage />} />
          <Route path="/subscriptions" element={<Subscription />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/Subscriber-Terms" element={<SubscriberTerms />} />
          <Route path="/auth/google/callback" element={<OAuthRedirect />} />
          <Route path="/forbidden" element={<Forbidden />} />
          <Route path="/admin" element={<AdminPage />} />
        </Routes>
      </div>
    </ConversationsContextProvider>
  );
};

const AppWithRouter = () => (
  <Router>
    <App />
  </Router>
);

const AppWithNotification = () => <AppWithRouter />;

const AppWithUser = () => (
  <UserProvider>
    <AppWithNotification />
  </UserProvider>
);

const AppWithInitialMessage = () => (
  <InitialMessageProvider>
    <AppWithUser />
  </InitialMessageProvider>
);

const AppWithConversation = () => (
  <ConversationProvider>
    <AppWithInitialMessage />
  </ConversationProvider>
);

export default AppWithConversation;
