import { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { apiPost } from '../tacktech/api'; // Assuming you have a helper for API calls

const ConsentSettings = () => {
  const { user, fetchUserData } = useAuth();
  console.log("User is", user);
  const [consentGiven, setConsentGiven] = useState(user.details.consent_given);
  const [consentUpdateMessage, setConsentUpdateMessage] = useState('');

  const handleConsentChange = (e) => {
    setConsentGiven(e.target.checked);
  };

  const handleConsentUpdate = async () => {
    try {
      await apiPost('api/auth/consent', { consent_given: consentGiven });
      setConsentUpdateMessage('Consent updated successfully.');
      fetchUserData(); // Refresh user data
    } catch (error) {
      console.error('Error updating consent:', error);
      setConsentUpdateMessage('Failed to update consent.');
    }
  };

  return (
    <div className="bg-zinc-900 p-6 rounded-lg shadow-lg">
      <h2 className="text-base font-semibold leading-7 text-white mb-4">Consent Settings</h2>
      <div className="flex flex-col space-y-4">
        <label className="text-white text-sm lg:text-xs">
          <input
            type="checkbox"
            checked={consentGiven}
            onChange={handleConsentChange}
            className="mr-2"
          />
          I consent to receive updates and notifications.
        </label>
        <button
          onClick={handleConsentUpdate}
          className="p-2 bg-amber-400 text-black rounded-md hover:opacity-75"
        >
          Update Consent
        </button>
        {consentUpdateMessage && (
          <p className="text-sm text-white">{consentUpdateMessage}</p>
        )}
      </div>
    </div>
  );
};

export default ConsentSettings;