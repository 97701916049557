import { ReactNode, useState } from 'react';
import { useSidebarCollapse } from '../contexts/SidebarCollapseContext';
import Sidebar from '../components/Sidebar';
import LoginModal from '../components/LoginModal';
import { NavbarTemplate } from './NavbarTemplate';
import { Toaster } from 'react-hot-toast';
import { Transition } from '@headlessui/react';

type PageSidebarTemplateProps = {
  children: ReactNode;
  NavbarContent?: JSX.Element;
};

export default function PageSidebarTemplate({
  children,
  NavbarContent,
}: PageSidebarTemplateProps) {
  const { isCollapsed } = useSidebarCollapse();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);

  const handleOpenLoginModal = () => setIsLoginModalOpen(true);
  const handleCloseLoginModal = () => setIsLoginModalOpen(false);

  const handleOpenSignupModal = () => setIsSignupModalOpen(true);
  const handleCloseSignupModal = () => setIsSignupModalOpen(false);

  return (
    <>
      <div className="relative inset-0 flex justify-center w-screen h-full max-h-screen">
        <Toaster />
        <aside
          className={`transition-all duration-300 ease-in-out ${
            isCollapsed ? 'w-0' : 'w-0 lg:w-[18rem] 2xl:w-[20rem]'
          }`}
        >
          <Sidebar />
        </aside>

        <main
          className={`flex-1 w-full h-full flex flex-col transition-all duration-300 ease-in-out transform ${
            isCollapsed ? 'ml-0' : ''
          }`}
        >
          <Transition show={!isCollapsed}>
            <div className="bg-zinc-900 absolute inset-0 z-[100] opacity-60 transition duration-300 ease-in data-[closed]:opacity-0 md:hidden"></div>
          </Transition>
          <NavbarTemplate
            onLoginClick={handleOpenLoginModal}
            onSignupClick={handleOpenSignupModal}
            Content={NavbarContent}
          />
          {children}
        </main>
      </div>
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={handleCloseLoginModal}
        initialFormType="login"
      />
      <LoginModal
        isOpen={isSignupModalOpen}
        onClose={handleCloseSignupModal}
        initialFormType="signup"
      />
    </>
  );
}
