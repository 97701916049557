import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { agentList, getAvatarUrl } from '../tacktech/api';
import { card_color } from '../utilities/ColorPalette';
import { useAuth } from '../contexts/AuthContext';
import HorizontalCardScroller from '../components/HorizontalCardScroller';
import ConversationCount from './ConversationCount';

export default function RecommmendAgentGrid() {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const auth = useAuth();

  useEffect(() => {
    if (!auth.sessionID) return;
    const fetchCards = async () => {

      setLoading(true);
      try {
        const data = await agentList({
          type: 'selected',
          name: 'grid',
          limit: 10,
          skip: 0,
        });
        setCards(data.agents);
        setLoading(false);
      } catch (error) {
        console.warn('Error fetching agent list:', error);
      }
    };
    fetchCards();
  }, [auth.sessionID]);

  return (
    <div className="flex-auto max-w-6xl mx-auto">
      <h1 className="text-xl md:text-2xl font-semibold text-gray-200 pb-2 mx-1">
        Recommended For You
      </h1>
      <div className="flex">
        <HorizontalCardScroller>
          {loading
            ? Array.from({ length: 4 }).map((_, index) => (
              <div
                key={index}
                className={`${card_color} w-64 h-70 text-white rounded-lg shadow-lg flex flex-col p-4 animate-pulse`}
              >
                <div className="w-full h-32 bg-gray-700 rounded-lg mb-4"></div>
                <div className="w-3/4 h-6 bg-gray-700 rounded mb-2"></div>
                <div className="w-1/2 h-6 bg-gray-700 rounded mb-4"></div>
                <div className="flex-grow">
                  <div className="w-full h-4 bg-gray-700 rounded mb-2"></div>
                  <div className="w-5/6 h-4 bg-gray-700 rounded mb-2"></div>
                  <div className="w-4/5 h-4 bg-gray-700 rounded"></div>
                </div>
                <div className="mt-4 flex items-center text-gray-400">
                  <div className="w-5 h-5 bg-gray-700 rounded-full"></div>
                  <div className="w-8 h-4 bg-gray-700 rounded ml-1"></div>
                  <div className="w-8 h-4 bg-gray-700 rounded ml-1"></div>
                </div>
              </div>
            ))
            : cards.map((card, index) => (
              <Link
                to={`/agents/${card.id}/chat`}
                className="flex-shrink-0"
                rel="prefetch"
              >
                <div
                  key={index}
                  className={`${card_color} text-white rounded-lg shadow-lg flex flex-col w-64 h-[26rem] p-4 hover:bg-zinc-950 hover:cursor-pointer`}
                >
                  <img
                    src={getAvatarUrl(card.avatar_url)}
                    alt={card.name}
                    className="w-full h-64 min-h-64 rounded-lg object-cover mb-4"
                  />
                  <h3 className="text-lg font-semibold hover:text-amber-400">
                    {card.name}
                  </h3>
                  <div className="flex-grow flex-col space-y-1">
                    <div className="flex justify-between items-start pr-2 pt-1 space-x-3">
                      <p className="text-gray-400 text-sm flex-shrink-0">
                        By @{card.owner.username}
                      </p>
                      <ConversationCount count={card.chats} className="flex-shrink" />
                    </div>
                    <p className="text-gray-400">{card.tagline}</p>
                  </div>
                </div>
              </Link>
            ))}
        </HorizontalCardScroller>
      </div>
    </div>
  );
}
