import { useState, useEffect } from 'react';
import ChatInput from "../components/ConversationChatInput";
import { useNavigate } from 'react-router-dom';
import { agentList } from '../tacktech/api';
import { useAuth } from '../contexts/AuthContext';
import { useInitialMessage } from '../contexts/InitialMessageContext';

// These msgs have to be around 20 chars including whitespaces to work w animation
const AGENT_MSG_EXAMPLES = [
  "Help me get fit",
  "Be my Japanese tutor",
  "Help me with math hw"
];

export default function Hero() {
  const auth = useAuth();
  const [agents, setAgents] = useState([]);
  const [numAgents, setNumAgents] = useState(0);
  const [loading, setLoading] = useState(true);
  const { setInitialMessage } = useInitialMessage();
  const navigate = useNavigate();
  const [msgIdx, setMsgIdx] = useState(0);

  useEffect(() => {
    if (!auth.sessionID) return;

    const fetchAgents = async () => {
      try {
        setLoading(true);
        const data = await agentList({ type: "selected", name: "banner", limit: 1, skip: 0 });
        setNumAgents(data.total);
        setAgents(data.agents);
      } catch (error) {
        console.warn('Error fetching agent list:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAgents();
  }, [auth.sessionID]);

  const agent = agents[0];

  const handleSendMessage = (message) => {
    if (!loading && agent && agent?.id) {
      setInitialMessage(message);
      navigate(`/agents/${agent.id}/chat`);
    } else {
      console.warn('Agent ID is undefined or still loading');
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setMsgIdx((prevIndex) => (prevIndex + 1) % AGENT_MSG_EXAMPLES.length);
    }, 6000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <section className="isolate relative max-w-4xl w-full mx-auto lg:mt-8 bg-zinc-900 h-[44dvh] 2xl:h-[40dvh] max-h-[515px] flex rounded-lg shadow-lg">
      <div className="absolute h-full right-0 lg:left-0 w-3/5 md:w-1/3 bg-top-right xl:bg-top-left bg-cover bg-no-repeat bg-hero-girl-mobile xl:bg-hero-girl bg-opacity-90 z-0 rounded-lg">

      </div>
      <div className="lg:pl-[33%] w-full h-full flex flex-col justify-center z-10">
        <div className="-mt-[26%] md:-mt-[10%] pl-2 md:pl-4 space-y-1 z-10">
          <h2 className="text-l font-semibold text-left text-white tracking-tight w-1/2 md:w-full">
            {numAgents !== null && numAgents !== undefined ? (
              `We have ${numAgents} agents available.`
            ) : (
              <span className="inline-block w-24 h-6 bg-gray-400 rounded animate-pulse"></span>
            )}
          </h2>
          <h1 className="text-xl font-semibold lg:text-3xl 2xl:text-4xl text-left text-white tracking-tight w-1/2 md:w-full">
            What do you need help with?
          </h1>
          <div className="w-fit m-1 pt-1 z-10">
            <h1 className="animate-typing overflow-hidden whitespace-nowrap border-r-4 border-r-white pr-4 py-1 text-md md:text-2xl 2xl:text-3xl text-white font-light">
              {AGENT_MSG_EXAMPLES[msgIdx]}
            </h1>
          </div>
        </div>
      </div>

      <div className="absolute left-1 right-1 bottom-2 mx-1 z-20" >
        <div className="flex justify-center">
          <ChatInput onSendMessage={handleSendMessage} canSend={true} />
        </div>
      </div>
    </section>
  );
}
