import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { removeCookie, setCookie, getCookie } from '../tacktech/cookie';
import { apiGet, apiPost, initApi } from '../tacktech/api';
import { validate as validateUUID } from 'uuid';

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [sessionID, setSessionID] = useState(null);
  const [user, setUser] = useState(null);
  const [userLoading, setLoading] = useState(true);

  const logout = async () => {
    try {
      await apiPost('api/auth/logout');
    } catch (err) {
      console.error("Error during logout: ", err);
    } finally {
      renewSession();
    }
  };

  const renewSession = useCallback(async () => {
    removeCookie('session');
    setUser(null);
    setSessionID(null);
  }, []);

  const fetchUserData = useCallback(async () => {
    // When no session ID exists there is no need to fetch user data
    // because there is no way that the user can be logged in.
    if (!sessionID) {
      return;
    }
    try {
      setLoading(true);
      const response = await apiGet('api/auth/me');
      setUser(response);
    } catch (err) {
      // Status code of 403 means the user isn't logged in.
      if (err.status !== 403) {
        console.error("Error fetching user data: ", err);
      }
      setUser(null);
    } finally {
      setLoading(false);
    }
  }, [sessionID]);

  const initializeSession = useCallback(async () => {
    let session = getCookie('session');
    if (!session || !validateUUID(session)) {
      try {
        session = await apiPost('api/auth/session');
        if (!validateUUID(session)) {
          throw new Error('Invalid session ID received from the server');
        }
        setCookie('session', session, 3);
      } catch (err) {
        console.error("Error fetching session: ", err);
        return;
      }
    }
    initApi(() => session, renewSession);
    setSessionID(session);
    fetchUserData();
  }, [renewSession, fetchUserData]);

  useEffect(() => {
    if (!sessionID) {
      initializeSession();
    }
  }, [sessionID, initializeSession]);

  useEffect(() => {
    if (sessionID) {
      fetchUserData();
    }
  }, [sessionID, fetchUserData]);

  const checkSessionStatus = useCallback(async () => {
    try {
      const response = await apiGet('api/auth/me');
      setUser(response);
    } catch (err) {
      console.error("Error checking session status: ", err);
      setUser(null);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      checkSessionStatus();
    }, 30 * 60 * 1000); // 30 minutes

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [checkSessionStatus]);

  return (
    <AuthContext.Provider value={{ fetchUserData, sessionID, logout, user, userLoading, renewSession }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
