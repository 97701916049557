import { ArrowUpTrayIcon } from '@heroicons/react/20/solid';
import toast from 'react-hot-toast';

type ShareButtonProps = {
  agentId: string;
};

const ShareButton = ({ agentId }: ShareButtonProps) => {
  const handleShareClick = () => {
    const baseUrl = `https://tacktech.ai/agents/${agentId}/chat/`;

    const tempInput = document.createElement('input');
    document.body.appendChild(tempInput);
    tempInput.value = baseUrl;

    tempInput.select();
    document.execCommand('copy');

    document.body.removeChild(tempInput);

    toast('Agent URL copied to clipboard!', {
      icon: '☑️',
      duration: 3000,
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
      },
    });
  };

  return (
    <button
      onClick={handleShareClick}
      className="flex items-center px-1.5 md:px-3 py-1 md:py-1.5 active:bg-zinc-600 bg-zinc-700 rounded-md text-white"
      aria-label="Share"
    >
      <ArrowUpTrayIcon className="h-5 w-5" />
      <p className="ml-1 text-xs hidden md:block ">Share Agent</p>
    </button>
  );
};

export default ShareButton;
