import React, { useState } from "react";
import { FaCheckCircle, FaExclamationTriangle } from "react-icons/fa";
import { AiOutlineClose, AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import LoadingProgressIndicator from "../LoadingProgressIndicator";

const ToolBlockContent = ({ tool, content }) => {
  switch (tool) {
    case "image":
      return (
        <div className="flex justify-center items-center">
          <img src={content} alt="Tool Block Content" className="w-full h-auto object-contain" />
        </div>
      );
    case "text":
      return (
        <div className="p-4">
          <p>{content}</p>
        </div>
      );
    // Add more cases for different types of content as needed
    default:
      return null;
  }
};

const StatusIcon = ({ status }) => {
  switch (status) {
    case "inProgress":
      return <LoadingProgressIndicator circleDiameter={24} strokeWidth={12} delay={140} />;
    case "done":
      return <FaCheckCircle className="mr-2 text-green-500" />;
    case "error":
      return <FaExclamationTriangle className="mr-2 text-red-500" />;
    default:
      return <div className="mr-2 w-4 h-4" />;
  }
};


const ToolBlock = ({ tool, message, isLoading, isError, cancelHandler }) => {
  const [isExpanded, setIsExpanded] = useState(isLoading);
  const [isHidden, setIsHidden] = useState(false);
  const image = message.payload?.payload?.data?.[0]?.url;
  console.log("Image", image, "Message", message);
  const toggleExpand = () => setIsExpanded(!isExpanded);
  const handleCancel = () => {
    if (cancelHandler) cancelHandler();
    setIsHidden(true);
  };

  let status = isLoading ? "inProgress" :
    isError ? "error" :
      "done";
  let summary = isLoading ? "Generating images..." :
    isError ? "Failed to generate image." :
      "Image generated.";

  return (
    <div className={`${isHidden && 'hidden'} border rounded shadow p-4 my-4 bg-slate-100 dark:bg-zinc-800 text-slate-900 dark:text-slate-100 mx-5}`}>
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <StatusIcon status={status} />
          <div className="mr-2">{summary}</div>
        </div>
        <div className="flex items-center">
          {image && (
            <button
              onClick={toggleExpand}
              className="text-gray-500 hover:text-gray-700 focus:outline-none mr-2"
            >
              {isExpanded ? <AiOutlineUp /> : <AiOutlineDown />}
            </button>
          )}
          <button onClick={handleCancel} className="text-gray-500 hover:text-gray-700">
            <AiOutlineClose />
          </button>
        </div>
      </div>
      {isExpanded && image && (
        <div className="mt-4">
          <ToolBlockContent tool={"image"} content={image} />
        </div>
      )}
    </div>
  );
};

export default ToolBlock;
