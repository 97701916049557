import React, { createContext } from 'react';
import { useAuth } from './AuthContext';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const { user, isLoggedIn, error } = useAuth();

  return (
    <UserContext.Provider value={{ user, isLoggedIn, error }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
