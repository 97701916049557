import React from "react";
import { apiPost } from "../tacktech/api";
import { Link } from "react-router-dom";
import { primary_hover } from "../utilities/ColorPalette";

const CustomerPortalLink = () => {
  const handleManageBilling = async () => {
    try {
      const response = await apiPost("api/payment/create-customer-portal-session")
      window.location.href = response.url;
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  }

  return (
    <Link to="#" className={`${primary_hover} font-semibold text-amber-400 hover:opacity-75 text-sm leading-6`}
      onClick={handleManageBilling}    >
      Manage billing
    </Link>
  );
};

export default CustomerPortalLink;
