import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import LoginButton from '../components/LoginButton';
import HomeButton from '../components/HomeButton';

type NavbarTemplateProps = {
  onLoginClick: () => void;
  onSignupClick: () => void;
  Content?: JSX.Element;
};

export const NavbarTemplate = ({
  onLoginClick,
  onSignupClick,
  Content,
}: NavbarTemplateProps) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <div className="flex w-full max-w-screen sticky min-h-12 md:min-h-14 z-[70] border-b border-slate-400/10">
      <div className="flex xl:max-w-7xl justify-between items-center w-full mx-auto">
        <div className="ml-12 md:ml-14 2xl:ml-2">
          <HomeButton />
        </div>
        <div className="flex-1">{Content}</div>
        <div className="w-fit flex items-center justify-end gap-1">
          <LoginButton
            isLoggedIn={!!user}
            handleLoginClick={onLoginClick}
            handleSignupClick={onSignupClick}
            handleLogoutClick={handleLogout}
          />
        </div>
      </div>
    </div>
  );
};
