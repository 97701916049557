import React from 'react';
import ConversationListItem from './ConversationListItem';

const ConversationList = ({ date, chats }) => {

  return (
    <div key={date} className="w-full m-1">
      <div className="text-xs font-semibold leading-6 text-gray-500">
        {date}
      </div>
      <ul className="space-y-2">
        {chats.map((chat) => (
          <ConversationListItem key={chat.id} chat={chat} />
        ))}
      </ul>
    </div>
  );
};

export default ConversationList;
