import { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { apiPost } from '../tacktech/api';

const UpdateDateOfBirth = () => {
  const { user, fetchUserData } = useAuth();
  const [newDateOfBirth, setNewDateOfBirth] = useState('');
  const [dateOfBirthUpdateMessage, setDateOfBirthUpdateMessage] = useState('');

  const handleDateOfBirthChange = (e) => {
    setNewDateOfBirth(e.target.value);
  };

  useEffect(() => {
    if (user && user.details && user.details.date_of_birth) {
      // Extract 'YYYY-MM-DD' from 'YYYY-MM-DDT00:00:00Z'
      const dob = user.details.date_of_birth.split('T')[0];
      setNewDateOfBirth(dob);
    }
  }, [user]);

  const handleDateOfBirthUpdate = async () => {
    try {
      await apiPost('api/auth/dob', { dob: newDateOfBirth });
      setDateOfBirthUpdateMessage('Date of birth updated successfully.');
      fetchUserData(); // Refresh user data
    } catch (error) {
      console.error('Error updating date of birth:', error);
      setDateOfBirthUpdateMessage('Failed to update date of birth.');
    }
  };

  return (
    <div className="bg-zinc-900 p-6 rounded-lg shadow-lg">
      <h2 className="text-base font-semibold leading-7 text-white mb-4">Update Date of Birth</h2>
      <div className="flex flex-col space-y-4">
        <input
          type="date"
          value={newDateOfBirth}
          onChange={handleDateOfBirthChange}
          className="w-full text-white text-md lg:text-sm placeholder:text-gray-400 bg-zinc-900 border-2 border-gray-700 rounded-lg focus:border-gray-700 focus:bg-zinc-950 focus:ring-0"
        />
        <button
          onClick={handleDateOfBirthUpdate}
          className="p-2 bg-amber-400 text-black rounded-md hover:opacity-75"
        >
          Update Date of Birth
        </button>
        {dateOfBirthUpdateMessage && (
          <p className="text-sm text-white">{dateOfBirthUpdateMessage}</p>
        )}
      </div>
    </div>
  );
};

export default UpdateDateOfBirth;
