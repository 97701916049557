import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { apiPost } from '../tacktech/api';
import toast from 'react-hot-toast';

const OAuthRedirect = () => {
  const navigate = useNavigate();
  const { sessionID, fetchUserData } = useAuth();
  const hasExecuted = useRef(false);
  const handleOAuthRedirect = useCallback(async (code, state) => {
    try {
      await apiPost('api/auth/oauth-callback', { code, state});
      await fetchUserData();
    } catch (error) {
      if (error.status === 400) {
        toast.error(error.message);
      }
      else {
        console.error('OAuth redirect failed:', error);
        toast.error('OAuth authentication failed.');
      }
    }
    finally {
      navigate('/');
    }
  });

  useEffect(() => {
    // Wait until session is loaded to call backend
    if (!sessionID) {
      return;
    }
    if (hasExecuted.current) {
      return;
    }
    hasExecuted.current = true;

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');

    if (code) {
      handleOAuthRedirect(code, state);
    } else {
      toast.error('Authorization code not found.');
    }
  }, [sessionID, navigate, handleOAuthRedirect, fetchUserData]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="p-6 bg-white rounded shadow-md">
        <p>Processing OAuth redirect...</p>
      </div>
    </div>
  );
};

export default OAuthRedirect;
