import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { settingsUpgradePlan } from '../utilities/analytics'
import { SparklesIcon } from '@heroicons/react/20/solid';
import CustomerPortalLink from '../components/CustomerPortalLink';
import SubscriptionsModal from './SubscriptionsModal'

function SubscriptionSettings({ loading, user }) {
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
    let subscription = user && user.subscription ? user.subscription : {};

    function formatDate(isoString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(isoString);
        return date.toLocaleDateString(undefined, options);
    }

    return (
        <div className="bg-zinc-900 p-6 rounded-lg shadow-lg">
            <h2 className="text-base font-semibold leading-7 text-white mb-4">Subscription</h2>

            {loading ? (
                <div className="animate-pulse">
                    <div className="h-6 bg-gray-700 rounded w-1/4 mb-2"></div>
                    <div className="h-4 bg-gray-700 rounded w-1/4 mb-2"></div>
                    <div className="h-4 bg-gray-700 rounded w-1/4 mb-4"></div>
                    <div className="h-6 bg-gray-700 rounded w-1/4"></div>
                </div>
            ) : (
                <>
                    {user && !subscription.status ? (
                        <button
                            onClick={() => {
                                setIsModalOpen(true); // Open the modal
                                settingsUpgradePlan(); // Track the analytics event
                            }}
                            className="flex items-center gap-2 p-2 rounded focus:outline-none bg-amber-400 hover:bg-amber-500 text-black"
                        >
                            <SparklesIcon className="h-6 w-6" />
                            <div className="text-left mx-2">
                                <p className="font-bold text-sm md:text-base">Upgrade Your Plan</p>
                                <p className="text-xs md:text-base text-gray-800">Raise quotas and unlock features</p>
                            </div>
                        </button>
                    ) : (
                        <>
                            <p className="mt-1 text-sm leading-6 text-white">
                                Status: {subscription.status}
                            </p>
                            <p className="mt-1 text-sm leading-6 text-white">
                                Automatically renews on {formatDate(subscription.current_period_end)}.
                            </p>
                            <CustomerPortalLink />
                            <br />
                            <p className="mt-1 text-sm leading-6 text-gray-400">
                                <Link to="/subscriber-terms" className="font-semibold text-amber-400 hover:opacity-75">Subscriber Terms</Link>
                            </p>
                        </>
                    )}
                </>
            )}
            <SubscriptionsModal
                isOpen={isModalOpen} // Pass modal state
                onClose={() => setIsModalOpen(false)} // Function to close the modal
            />
        </div>
    );
}

export default SubscriptionSettings;