import { ReactNode, useEffect, useRef, useState } from 'react';

type HorizontalScrollerProps = {
  children: ReactNode;
};

export default function HorizontalScroller({
  children,
}: HorizontalScrollerProps) {
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (!scrollContainerRef.current) return;
    const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
    setShowLeftButton(scrollLeft > 0);
    setShowRightButton(scrollLeft < scrollWidth - clientWidth);
  };

  const scrollLeft = () => {
    if (scrollContainerRef && scrollContainerRef) {
      scrollContainerRef?.current?.scrollBy({ left: -500, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef?.current?.scrollBy({ left: 500, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    handleScroll();
  }, []);

  return (
    <div className="relative w-full">
      {showLeftButton && (
        <button
          className="absolute left-0 top-0 bottom-0 h-full bg-zinc-950 text-white p-1 md:p-4 rounded-r-md opacity-40 md:opacity-80 hover:opacity-85 active: transition-opacity flex items-center justify-center z-10"
          onClick={scrollLeft}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
      )}
      <div
        className="overflow-x-auto scrollbar-hide py-1"
        ref={scrollContainerRef}
        onScroll={handleScroll}
      >
        <div className="inline-flex space-x-4">{children}</div>
      </div>
      {showRightButton && (
        <button
          className="absolute right-0 top-0 bottom-0 h-full bg-zinc-950 text-white p-1 md:p-4 rounded-l-md opacity-40 md:opacity-80 hover:opacity-85 transition-opacity flex items-center justify-center z-10"
          onClick={scrollRight}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      )}
    </div>
  );
}
