import React, { useEffect, useState } from 'react';
import CreateAgentButton from './agent/CreateAgentButton.jsx';
import MyProfileLink from '../components/MyProfile.jsx';
import { useAuth } from '../contexts/AuthContext.jsx';
import ConversationHistory from './ConversationHistory.jsx';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  SparklesIcon,
} from '@heroicons/react/20/solid';
import { primary_hover } from '../utilities/ColorPalette';
import { sidebarUpgradePlan } from '../utilities/analytics';
import { useSidebarCollapse } from '../contexts/SidebarCollapseContext';
import LoginModal from './LoginModal.jsx';
import SubscriptionsModal from './SubscriptionsModal.jsx';
import { useConversationsContext } from '../contexts/ConversationsContext';
import Logo from "../components/Logo.jsx"

const SidebarFooter = ({ onLogin, onSignup, onOpenSubscriptions }) => {
  const { user, userLoading } = useAuth();

  return (
    <div className="flex flex-col text-white text-sm mt-auto px-2 lg:px-4 py-2 lg:py-4">
      {userLoading ? (
        <div className="animate-pulse">
          <div className="mb-2">
            <div className="h-4 bg-gray-700 rounded w-1/3 mb-2"></div>
            <div className="h-3 bg-gray-600 rounded w-2/3"></div>
          </div>
          <div className="flex flex-col gap-2 w-full">
            <div className="h-10 bg-gray-700 rounded"></div>
            <div className="h-10 bg-gray-700 rounded"></div>
          </div>
        </div>
      ) : user ? (
        <>
          {(user && !user.subscription) ||
            (user.subscription && user.subscription.status !== 'active') ? (
            <button
              className="flex items-center gap-2 p-2 rounded text-white"
              onClick={(event) => {
                onOpenSubscriptions(event); // Trigger SubscriptionsModal on click
                sidebarUpgradePlan(event); // analytics
              }}
            >
              <SparklesIcon className={`h-6 w-6 ${primary_hover}`} />
              <div className="text-left ml-2">
                <p className={`font-bold ${primary_hover}`}>Upgrade plan</p>
                <p className="text-gray-400">
                  Raise quotas and unlock features
                </p>
              </div>
            </button>
          ) : null}
        </>
      ) : (
        <>
          <div className="mb-2">
            <p className="font-bold">Sign up or log in</p>
            <p>
              Get additional quota, more features, and avoid losing your
              conversations.
            </p>
          </div>
          <div className="flex flex-col gap-2 w-full">
            <button
              onClick={onSignup} // Open signup modal
              className="text-black hover:bg-yellow-400 active:bg-yellow-600 bg-yellow-500 shadow-md active:shadow-inner px-4 py-2 rounded w-full text-center"
            >
              Sign up
            </button>
            <button
              onClick={onLogin} // Open login modal
              className="text-white hover:bg-gray-600 bg-gray-700 active:bg-gray-900 shadow-md active:shadow-inner px-4 py-2 rounded w-full text-center"
            >
              Log in
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const Sidebar = ({ isCollapsed }) => {
  const { user } = useAuth();
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false); // State for login modal visibility
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false); // State for signup modal visibility
  const [isSubscriptionsModalOpen, setIsSubscriptionsModalOpen] = useState(false); // State for subscriptions modal visibility
  const { conversationsInfo } = useConversationsContext();
  let { conversations, loading, error } = conversationsInfo;

  const [filteredConversations, setFilteredConversations] = useState([]);

  useEffect(() => {
    if (!loading) {
      if (searchQuery === '') {
        setFilteredConversations(conversations)
      };
      const filtered = conversations?.filter((convo) =>
        convo?.name?.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredConversations(filtered);
    }
  }, [conversations, loading, searchQuery])


  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  }

  const openLoginModal = () => {
    setIsSignupModalOpen(false); // Ensure signup modal is closed
    setIsLoginModalOpen(true); // Open login modal
  };

  const openSignupModal = () => {
    setIsLoginModalOpen(false); // Ensure login modal is closed
    setIsSignupModalOpen(true); // Open signup modal
  };

  const openSubscriptionsModal = () => {
    setIsSubscriptionsModalOpen(true); // Open subscriptions modal
  };

  const closeModal = () => {
    setIsLoginModalOpen(false); // Close login modal
    setIsSignupModalOpen(false); // Close signup modal
    setIsSubscriptionsModalOpen(false); // Close subscriptions modal
  };

  return (
    <>
      <div
        className={`fixed inset-0 h-full px-2 w-[16rem] lg:w-[18rem] 2xl:w-[20rem] z-20 bg-zinc-900 transition-transform duration-300 ease-in-out transform ${isCollapsed ? '-translate-x-full' : 'translate-x-0'} border-r border-transparent bg-gradient-to-r from-transparent to-zinc-100/10 shadow-md shadow-zinc-900/10`}
      >
        <div className="z-[100] fixed top-2 md:top-3 right-3">
          <Logo />
        </div>
        <nav className=" flex flex-col space-y-3 pt-14 md:pt-16 px-1 z-30">
          {user ? (
            <div className="flex flex-col space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0 w-full">
              {/* Adjusted for mobile and desktop */}
              <CreateAgentButton />
              <MyProfileLink isSidebar={true} />
            </div>
          ) : (
            <div className="flex flex-col space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0 w-full items-center">
              {/* Adjust for Guest Users */}
              <CreateAgentButton onLogin={openLoginModal} />
            </div>
          )}
          <div className="relative mb-2">
            <input
              type="text"
              className="w-full text-white text-xs placeholder:text-gray-400 bg-zinc-900 border-2 border-gray-700 rounded-lg focus:border-gray-700 focus:bg-zinc-950 focus:ring-0"
              placeholder="Search Chat History..."
              value={searchQuery}
              onChange={handleSearch}
            />
            {searchQuery && filteredConversations.length === 0 && (
              <div className="absolute top-full left-0 w-full rounded-lg bg-zinc-900 text-gray-400 mt-1 py-2 z-10">
                No results
              </div>
            )}
          </div>
          <ul>
            <li>
              <div className="text-lg font-semibold text-white">
                Chat History
              </div>
              <div
                className={`fixed z-0 top-[14rem] md:top-[12rem] ${user ? 'bottom-[6.5rem] lg:bottom-[6rem]' : 'top-[11rem] bottom-[10.25rem] lg:bottom-[11rem]'} px-3 left-0 w-[16rem] lg:w-[18rem] 2xl:w-[20rem] overflow-y-auto block border-b-2 border-gray-700/70 `}
              >
                <ConversationHistory
                  loading={loading}
                  error={error}
                  chatHistory={filteredConversations}
                />
              </div>
            </li>
          </ul>
        </nav>

        <footer className="fixed bottom-0 left-0 w-[16rem] lg:w-[18rem] 2xl:w-[20rem]">
          <SidebarFooter
            onLogin={openLoginModal}
            onSignup={openSignupModal}
            onOpenSubscriptions={openSubscriptionsModal}
          />
        </footer>
      </div>
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={closeModal}
        initialFormType="login"
      />
      <SubscriptionsModal
        isOpen={isSubscriptionsModalOpen}
        onClose={closeModal}
      />
      <LoginModal
        isOpen={isSignupModalOpen}
        onClose={closeModal}
        initialFormType="signup"
      />
    </>
  );
};

const CollapsibleSidebar = () => {
  const { isCollapsed, setIsCollapsed } = useSidebarCollapse();

  return (
    <div className="absolute z-10 inset-y-0">
      <button
        onClick={() => setIsCollapsed(!isCollapsed)}
        className="bg-black border-4 border-gray-700 text-gold rounded p-1 fixed top-1 md:top-2 left-1 md:left-2 z-30"
      >
        {isCollapsed ? (
          <ChevronDoubleRightIcon className="h-6 w-6 text-amber-400" />
        ) : (
          <ChevronDoubleLeftIcon className="h-6 w-6 text-amber-400" />
        )}
      </button>
      <Sidebar isCollapsed={isCollapsed} />
    </div>
  );
};

export default CollapsibleSidebar;
