import React from "react";
import InputField from '../../components/InputField';
import FormSection from "../FormSection";

const SuggestionSettings = ({ formData, errors, handleInputChange }) => {
    return (
      <FormSection
        title="Suggestions"
        description="Help people understand what your agent can do for them by giving suggestions on first messages to send to the agent."
      >
        <div className="mt-1">
          <InputField
            id="suggestion_one"
            name="suggestion_one"
            label="Suggestion One"
            value={formData.suggestion_one}
            errorMessage={errors.fields.suggestion_one}
            maxLength={128}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <InputField
            id="suggestion_two"
            name="suggestion_two"
            label="Suggestion Two"
            value={formData.suggestion_two}
            errorMessage={errors.fields.suggestion_two}
            maxLength={128}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <InputField
            id="suggestion_three"
            name="suggestion_three"
            label="Suggestion Three"
            value={formData.suggestion_three}
            errorMessage={errors.fields.suggestion_three}
            maxLength={128}
            onChange={handleInputChange}
          />
        </div>
      </FormSection>
    );
};

export default SuggestionSettings;