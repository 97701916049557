import React from 'react';
import { format, parseISO, isValid } from 'date-fns';

import ConversationList from './ConversationList';

const Loading = () => {
  return (
    <div className="animate-pulse">
      <div className="h-6 bg-gray-300 rounded mt-4"></div>
      <div className="mt-4">
        <div className="h-6 bg-gray-300 rounded"></div>
        <ul className="-mx-2 mt-2 space-y-1">
          <li className="flex items-center gap-x-3 p-2">
            <div className="h-10 w-10 bg-gray-300 rounded-full"></div>
            <div className="h-6 bg-gray-300 rounded w-3/4"></div>
          </li>
          <li className="flex items-center gap-x-3 p-2">
            <div className="h-10 w-10 bg-gray-300 rounded-full"></div>
            <div className="h-6 bg-gray-300 rounded w-3/4"></div>
          </li>
          <li className="flex items-center gap-x-3 p-2">
            <div className="h-10 w-10 bg-gray-300 rounded-full"></div>
            <div className="h-6 bg-gray-300 rounded w-3/4"></div>
          </li>
        </ul>
      </div>
    </div>
  );
};

const ConversationHistory = ({ loading, error, chatHistory }) => {
  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div className="text-gray-200">Error loading.</div>;
  }

  if (chatHistory.length === 0) {
    return <div className="text-gray-200">No chat history available.</div>;
  }

  const groupedChatHistory = chatHistory.reduce((groups, chat) => {
    const parsedDate = parseISO(chat.last_updated);
    if (!isValid(parsedDate)) {
      console.error('Invalid date format for chat entry:', chat.last_updated);
      return groups;
    }

    const date = format(parsedDate, 'yyyy-MM-dd');
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(chat);
    return groups;
  }, {});

  // Sort the dates in descending order
  const sortedDates = Object.keys(groupedChatHistory).sort(
    (a, b) => new Date(b) - new Date(a)
  );

  // Sort chats within each date group in descending order by last_updated
  sortedDates.forEach((date) => {
    groupedChatHistory[date].sort(
      (a, b) => new Date(b.last_updated) - new Date(a.last_updated)
    );
  });

  return (
    <div className="mb-1 pr-2">
      {sortedDates.map((date) => (
        <ConversationList
          key={date}
          date={date}
          chats={groupedChatHistory[date]}
        />
      ))}
    </div>
  );
};

export default ConversationHistory;
