import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import InputField from './InputField';
import { useAuth } from '../contexts/AuthContext';
import {
  primary_hover,
  hover_fade,
  card_color,
} from '../utilities/ColorPalette';
import { apiPost } from '../tacktech/api';
import toast, { Toaster } from 'react-hot-toast';
import { accountSignUp, resetPassword } from '../utilities/analytics';

const GoogleLogo = () => {
  return (
    <img
      src="https://developers.google.com/identity/images/g-logo.png"
      alt="Google logo"
      className="mr-2"
      style={{ width: '20px', height: '20px' }}
    />
  );
};

const LoginDivider = () => {
  return (
    <div className="flex items-center justify-center my-4">
      <hr className="flex-grow border-t border-gray-400" />
      <span className="mx-4 text-gray-400">OR</span>
      <hr className="flex-grow border-t border-gray-400" />
    </div>
  );
};

const LoginModal = ({ isOpen, onClose, initialFormType = 'login' }) => {
  // Added initialFormType prop
  const { fetchUserData } = useAuth();
  const navigate = useNavigate();
  const location = useLocation(); // To capture the current URL
  const [formType, setFormType] = useState(initialFormType); // State to toggle between login, signup, and reset password
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState(''); // For signup form
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({
    fields: {},
  });
  const [redirectPath, setRedirectPath] = useState('/'); // State to store the redirect path

  useEffect(() => {
    setErrors({ fields: {} });
  }, [formType]);

  useEffect(() => {
    if (isOpen) {
      // When the modal opens, capture the current pathname
      setRedirectPath(location.pathname);
      setFormType(initialFormType); // Set form type based on initialFormType prop when modal opens
    }
  }, [isOpen, location.pathname, initialFormType]);

  if (!isOpen) return null;

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === 'email') {
      setEmail(value);
      if (errors.fields.email) {
        setErrors({ ...errors, fields: { ...errors.fields, email: [] } });
      }
    } else if (id === 'password') {
      setPassword(value);
      if (errors.fields.password) {
        setErrors({ ...errors, fields: { ...errors.fields, password: [] } });
      }
    } else if (id === 'username') {
      // Only used in signup form
      if (errors.fields.username) {
        setErrors({ ...errors, fields: { ...errors.fields, username: [] } });
      }
      setUsername(value);
    }
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const data = { email, password };
      await apiPost('api/auth/login', data);
      await fetchUserData();
      setErrors({ fields: {} });
      onClose(); // Close the modal on successful login

      navigate(redirectPath); // Redirect to the captured path
    } catch (error) {
      setErrors({
        message: error.message || '',
        fields: error.fields || {},
      });
      if (error.status !== 401) {
        console.error("Login error", error);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleSignupSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const data = { username, email, password };
      await apiPost('api/auth/signup', data);
      await fetchUserData();
      setErrors({ fields: {} });
      onClose(); // Close the modal on successful signup
      navigate(redirectPath); // Redirect to the captured path
    } catch (error) {
      console.error('Signup failed:', error);
      setErrors({
        message: error.message || '',
        fields: error.fields || {},
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const data = { email };
      await apiPost('api/auth/request-password-reset', data);
      setErrors({ fields: {} });
      toast.success(
        'Password reset email sent. Please check your email for instructions on how to reset your password.',
        {
          icon: '☑️',
          duration: 10000, // Extend duration to 10 seconds
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
      );
      onClose(); // Close the modal after sending the reset password email
    } catch (error) {
      console.error('Password reset failed:', error.message);
      setErrors({ message: error.message || '', fields: error.fields || {} });
    } finally {
      setSubmitting(false);
    }
  };

  const toggleFormType = (type) => {
    setFormType(type);
  };

  function handleCloseClick() {
    setErrors({ message: '', fields: {} });
    onClose();
  }

  const handleGoogleLogin = async() => {
    const response = await apiPost('api/auth/oauth-google');
    window.location.href = response;
  };

  return (
    <div className="relative top-0 bottom-0 left-0 right-0 z-50">
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
        <div
          className={`bg-zinc-900 p-6 rounded-lg shadow-lg relative max-h-full w-full max-w-md mx-auto sm:w-11/12 ${card_color} overflow-y-auto`}
        >
          <button
            className="absolute top-0 right-0 text-white"
            onClick={handleCloseClick}
          >
            <svg
              className="fill-gray-200 size-6 m-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </button>
          <h2 className="text-2xl text-amber-400 text-center my-4">
            {formType === 'login' && 'Login'}
            {formType === 'signup' && 'Sign up'}
            {formType === 'resetPassword' && 'Reset your password'}
          </h2>

          {formType === 'login' && (
            <form onSubmit={handleLoginSubmit} className="pt-2">
              <InputField
                id="email"
                type="email"
                autoComplete="email"
                label="Email"
                placeholder="Email"
                value={email}
                errorMessage={errors.fields.email}
                onChange={handleInputChange}
                isRequired={false}
              />
              <InputField
                id="password"
                type="password"
                autoComplete="current-password"
                label="Password"
                placeholder="Password"
                value={password}
                onChange={handleInputChange}
                errorMessage={errors.fields.password}
                isRequired={false}
              />
              <p className={`${errors.message ? 'visible' : 'invisible'} text-red-500 text-xs mb-0.5`}>{errors.message ?? 'No error'}</p>
              <button
                type="submit"
                className={`w-full py-2 mb-3 flex items-center justify-center bg-amber-400 text-black rounded-md hover:opacity-75 ${
                  submitting ? 'opacity-50' : 'opacity-100'
                }`}
                disabled={submitting}
              >
                Login
              </button>
              <LoginDivider />
              <button
                type="button"
                onClick={handleGoogleLogin}
                className="w-full py-2 mb-3 flex items-center justify-center bg-white text-black rounded-md hover:opacity-75"
              >
                <GoogleLogo />
                Sign in with Google
              </button>
            </form>
          )}

          {formType === 'signup' && (
            <form onSubmit={handleSignupSubmit}>
              <InputField
                id="username"
                label="Username"
                placeholder="Username"
                value={username}
                maxLength={15}
                errorMessage={errors.fields.username}
                onChange={handleInputChange}
                isRequired={false}
              />
              <InputField
                id="email"
                type="email"
                autoComplete="email"
                label="Email"
                placeholder="Email"
                value={email}
                errorMessage={errors.fields.email}
                onChange={handleInputChange}
                isRequired={false}
              />
              <InputField
                id="password"
                type="password"
                autoComplete="new-password"
                label="Password"
                placeholder="Password"
                value={password}
                onChange={handleInputChange}
                errorMessage={errors.fields.password}
                minLength={8}
                isRequired={false}
              />
              <button
                type="submit"
                onClick={accountSignUp}
                className={`w-full py-2 mb-3 flex items-center justify-center bg-amber-400 text-black rounded-md hover:opacity-75 ${
                  submitting ? 'opacity-50' : 'opacity-100'
                }`}
                disabled={submitting}
              >
                Sign Up
              </button>
              <LoginDivider />
              <button
                type="button"
                onClick={handleGoogleLogin}
                className="w-full py-2 mb-3 flex items-center justify-center bg-white text-black rounded-md hover:opacity-75"
              >
                <GoogleLogo />
                Sign up with Google
              </button>
            </form>
          )}

          {formType === 'resetPassword' && (
            <form onSubmit={handleForgotPasswordSubmit}>
              <p className="text-sm mb-6 text-center text-white">
                Enter your email address and we will send you instructions to
                reset your password.
              </p>
              <InputField
                id="email"
                type="email"
                autoComplete="email"
                label="Email"
                placeholder="Email"
                value={email}
                errorMessage={errors.fields.email}
                onChange={handleInputChange}
                isRequired={true}
              />
              <button
                type="submit"
                onClick={resetPassword}
                className={`w-full py-2 mb-3 flex items-center justify-center bg-amber-400 text-black rounded-md hover:opacity-75 ${
                  submitting ? 'opacity-50' : 'opacity-100'
                }`}
                disabled={submitting}
              >
                Request Reset
              </button>
              <div className="flex justify-center mt-4">
                <button
                  className={`text-sm text-white ${primary_hover} ${hover_fade}`}
                  onClick={() => toggleFormType('login')}
                >
                  Back to Login
                </button>
              </div>
            </form>
          )}

          <div className="mt-4 pt-4 space-y-4">
            {formType === 'login' && (
              <div className="flex justify-center">
                <button
                  className={`text-sm text-white ${primary_hover} ${hover_fade}`}
                  onClick={() => toggleFormType('resetPassword')}
                >
                  Forgot Password?
                </button>
              </div>
            )}

            {formType !== 'resetPassword' && (
              <div className="flex justify-center">
                <button
                  className={`text-sm text-white ${primary_hover} ${hover_fade}`}
                  onClick={() =>
                    toggleFormType(formType === 'login' ? 'signup' : 'login')
                  }
                >
                  {formType === 'login'
                    ? "Don't have an account? Sign up"
                    : 'Already have an account? Login'}
                </button>
              </div>
            )}

            {formType !== 'resetPassword' && (
              <div className="flex justify-center space-x-4 text-sm text-white">
                <button
                  onClick={() => {
                    handleCloseClick();
                    navigate('/terms');
                  }}
                  className={`${primary_hover} ${hover_fade} inline-block`}
                >
                  Terms of Use
                </button>
                <span className="text-white">|</span>
                <button
                  onClick={() => {
                    handleCloseClick();
                    navigate('/privacy');
                  }}
                  className={`${primary_hover} ${hover_fade} inline-block`}
                >
                  Privacy Policy
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default LoginModal;
