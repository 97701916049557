import {
  TOOL_USE_STARTING,
  TOOL_USE_PROGRESS_DELTA,
  TOOL_USE_DONE,
  TOOL_USE_ERRORED,
  MESSAGE_STARTING,
  MESSAGE_PROGRESS_DELTA,
  MESSAGE_DONE,
  MESSAGE_ERRORED,
  ERROR,
  ADD_MESSAGE,
  UPDATE_LAST_LOADING_MESSAGE,
  SET_MESSAGES,
  RESET_MESSAGES,
  DELETE_UPSELL_MESSAGES,
} from './actions'; // Ensure these are defined and exported in your actions file.

const initialState = {
  conversations: [],
  conversationData: {}, // Changed to an object keyed by conversation IDs
};

// Utility function to find the index of the currently loading message
const updateMessageById = (messages, id, updateFn) => {
  const messageIndex = messages.findIndex((message) => message.id === id);
  if (messageIndex === -1) {
    console.error(`Message with ID ${id} not found.`);
    return messages;
  }
  const updatedMessages = [...messages];
  updatedMessages[messageIndex] = updateFn(updatedMessages[messageIndex]);
  return updatedMessages;
};

const getLoadingMessageIndex = (messages) =>
  messages.findIndex((message) => message.isLoading);

const updateMessages = (state, conversationId, updateFn) => {
  const messages = state.conversationData[conversationId]?.messages || [];
  const updatedMessages = updateFn(messages);
  return {
    ...state,
    conversationData: {
      ...state.conversationData,
      [conversationId]: {
        ...state.conversationData[conversationId],
        messages: updatedMessages,
      },
    },
  };
};

const handleLoadingMessageIndex = (messages, updateFn) => {
  const lastIndex = getLoadingMessageIndex(messages);
  if (lastIndex === -1) {
    console.error('No message is currently loading.');
    return messages;
  }
  return updateFn(messages, lastIndex);
};

// Utility function to update the full message
const updateFullMessage = (messages, payload) => {
  return handleLoadingMessageIndex(messages, (msgs, index) => {
    const updatedMessages = [...msgs];
    updatedMessages[index] = { ...updatedMessages[index], ...payload };
    return updatedMessages;
  });
};

// Utility function to update a message chunk
const updateMessageChunk = (messages, payload) =>
  handleLoadingMessageIndex(messages, (msgs, index) => {
    const lastMessage = msgs[index];
    const chunkPayload = {
      ...payload,
      content: (lastMessage.content || '') + payload.content,
    };
    return updateFullMessage(msgs, chunkPayload);
  });

// Utility function to mark a message as done
const markMessageAsDone = (messages) =>
  handleLoadingMessageIndex(messages, (msgs, index) => {
    const updatedMessages = [...msgs];
    updatedMessages[index] = {
      ...updatedMessages[index],
      isLoading: false,
      done: true,
    };
    return updatedMessages;
  });

// Reducer function to handle state changes
const reducer = (state, action) => {
  // console.log('Action is ', state, action);
  switch (action.type) {
    case ERROR:
      if (action.payload.status === 403) {
        return {
          ...state,
          conversationData: {},
        };
      }
      return {
        ...state,
        conversationData: {
          ...state.conversationData,
          [action.conversationId]: {
            ...state.conversationData[action.conversationId],
            error: action.payload,
            loading: false,
          },
        },
      };
    case ADD_MESSAGE:
      return {
        ...state,
        conversationData: {
          ...state.conversationData,
          [action.conversationId]: {
            ...state.conversationData[action.conversationId],
            messages: [
              ...(state.conversationData[action.conversationId]?.messages ||
                []),
              action.payload,
            ],
          },
        },
      };
    case UPDATE_LAST_LOADING_MESSAGE:
      return updateMessages(state, action.conversationId, (messages) =>
        updateFullMessage(messages, action.payload)
      );
    case SET_MESSAGES:
      return {
        ...state,
        conversationData: {
          ...state.conversationData,
          [action.conversationId]: {
            ...state.conversationData[action.conversationId],
            messages: action.payload,
            loading: false,
          },
        },
      };
    case RESET_MESSAGES:
      return {
        ...state,
        conversationData: {
          ...state.conversationData,
          [action.conversationId]: {
            ...state.conversationData[action.conversationId],
            messages: [],
          },
        },
      };
      case TOOL_USE_STARTING:
        return updateMessages(state, action.conversationId, (messages) => [
          ...messages,
          {
            id: action.payload.id,
            payload: action.payload,
            isLoading: true,
            type: 'tool.usage',
            done: false,
          },
        ]);
      case TOOL_USE_PROGRESS_DELTA:
        return updateMessages(state, action.conversationId, (messages) =>
          updateMessageById(messages, action.payload.id, (message) => ({
            ...message,
            payload: action.payload,
            isLoading: false,
            done: true
          }))
        );
      case TOOL_USE_DONE:
        return updateMessages(state, action.conversationId, (messages) =>
          updateMessageById(messages, action.payload.id, (message) => ({
            ...message,
            isLoading: false,
            done: true,
          }))
        );
      case TOOL_USE_ERRORED:
        return updateMessages(state, action.conversationId, (messages) =>
          updateMessageById(messages, action.payload.id, (message) => ({
            ...message,
            isLoading: false,
            error: action.payload.error,
          }))
        );
    case MESSAGE_STARTING:
    case MESSAGE_PROGRESS_DELTA:
      return updateMessages(state, action.conversationId, (messages) =>
        updateMessageChunk(messages, action.payload)
      );
    case MESSAGE_DONE:
      return updateMessages(state, action.conversationId, (messages) =>
        markMessageAsDone(messages)
      );
    case MESSAGE_ERRORED:
      return updateMessages(state, action.conversationId, (messages) => {
        const updatedMessages = markMessageAsDone(messages);
        return {
          ...updatedMessages,
          error: action.payload,
        };
      });
    case DELETE_UPSELL_MESSAGES:
      return updateMessages(state, action.conversationId, (messages) =>
        messages.filter(
          (message) =>
            !(
              message.type === 'upsell' &&
              message.subtype === action?.payload?.subtype
            )
        )
      );
    default:
      return state;
  }
};

export { initialState, reducer };
