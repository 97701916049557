import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useSidebarCollapse } from '../contexts/SidebarCollapseContext';

export default function ChatInput({ onSendMessage, canSend = true }) {
  const [input, setInput] = useState('');
  const textareaRef = useRef(null);
  const containerRef = useRef(null);
  const { isCollapsed } = useSidebarCollapse();
  let isSmallerThanMediumScreen = window.innerWidth < 900;
  let { pathname } = useLocation();

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [input]);

  useEffect(() => {
    if (isSmallerThanMediumScreen && !isCollapsed) {
      // In this case do not proceed to focus input
      return;
    }
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [isCollapsed, pathname, isSmallerThanMediumScreen]);

  const handleSend = (e) => {
    e.preventDefault();
    if (input.trim() && canSend) {
      onSendMessage(input);
      setInput('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && (e.shiftKey || e.ctrlKey || e.metaKey)) {
      setInput((prevInput) => prevInput + '\n');
      e.preventDefault();
    } else if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey && !e.metaKey) {
      handleSend(e);
    }
  };

  const placeholder = "Message Agent";

  return (
    // ml-2 is for offset for collapse
    <div className="relative flex items-center max-w-4xl flex-auto p-3 rounded-lg">
      <form className="flex-grow" onSubmit={(e) => handleSend(e)}>
        <div ref={containerRef} className="flex items-end border border-gray-700 rounded-lg bg-zinc-900 p-2 absolute bottom-0 left-0 right-0">
          <label htmlFor="message" className="sr-only">
            {placeholder}
          </label>
          <textarea
            ref={textareaRef}
            rows={1}
            name="message"
            className="max-h-[35dvh] p-2 m-0 min-h-10 w-full resize-none bg-zinc-900 border-0 text-white placeholder:text-gray-400 focus:ring-0 overflow-y-auto text-md"
            placeholder={placeholder}
            value={input}
            onKeyDown={handleKeyDown}
            onChange={(e) => setInput(e.target.value)}
          />
          <div className="flex-shrink-0">
            <button
              type="submit"
              className="flex items-center px-4 py-1.5 bg-amber-400 text-black font-bold hover:bg-yellow-600 rounded-lg ml-2 disabled:bg-yellow-600"
              disabled={!input.trim() && !canSend}
            >
              Send
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
