import { useEffect, useState } from 'react';

type LoadingProgressIndicatorProps = {
  circleDiameter: number;
  strokeWidth: number;
  delay: number;
};

export default function LoadingProgressIndicator({
  circleDiameter,
  strokeWidth,
  delay,
}: LoadingProgressIndicatorProps) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount >= 100) {
          clearInterval(timer);
          return 100;
        }
        return prevCount + 1;
      });
    }, delay);
    return () => {
      clearInterval(timer);
    };
  }, [delay]);

  return (
    <div className="mr-2 mt-0.5">
      <ProgressCircle
        strokeWidth={strokeWidth}
        percentage={count}
        circleDiameter={circleDiameter}
      />
    </div>
  );
}

type ProgressCircleProps = {
  strokeWidth: number;
  percentage: number;
  circleDiameter: number;
};

const ProgressCircle = ({
  strokeWidth,
  percentage,
  circleDiameter,
}: ProgressCircleProps) => {
  const radius = 50 - strokeWidth / 2;
  const pathDescription = `
      M 50,50 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;

  const diameter = Math.PI * 2 * radius;
  const progressStyle = {
    stroke: '#eab308',
    strokeDasharray: `${diameter}px ${diameter}px`,
    strokeLinecap: 'round',
    strokeDashoffset: `${((100 - percentage) / 100) * diameter}px`,
  };

  return (
    <svg viewBox="0 0 100 100" width={circleDiameter} height={circleDiameter}>
      <path
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        style={{ stroke: '#000000' }}
      />
      <path
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        style={progressStyle} // Ignore this warning it works
      />
    </svg>
  );
};
