import { createContext, useReducer, useContext } from 'react';
import { initialState, reducer } from '../tacktech/chat/reducers';

const MessagesContext = createContext();

export const useMessagesContext = () => useContext(MessagesContext);

const MessagesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <MessagesContext.Provider value={{ state, dispatch }}>
      {children}
    </MessagesContext.Provider>
  );
};

export default MessagesProvider;
