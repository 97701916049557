import React, { useState, useEffect } from 'react';
import LargeAvatar from './LargeAvatar';
import { Helmet } from 'react-helmet';
import { useAgentContext } from '../contexts/AgentContext';
import truncateText from '../utilities/utilities';
import { apiGet } from '../tacktech/api';

const DEFAULT_AGENT_INFO: AgentInfo = {
  name: '',
  avatar: '',
  description: '',
  tools: [],
  suggestions: [],
  isLoading: false,
  id: -1,
};

type AgentInfo = {
  name: string;
  avatar: string;
  description: string;
  tools: any[];
  suggestions: any[];
  isLoading: boolean;
  id: number;
};

type ChatAgentGreeterProps = {
  handleSuggestionClick: (suggestion: string) => void;
};

export default function ChatAgentGreeter({
  handleSuggestionClick,
}: ChatAgentGreeterProps) {
  const agentInfo = useAgentContext() ?? DEFAULT_AGENT_INFO;
  const agentSuggestions = agentInfo.suggestions;

  return (
    <section className="flex flex-col justify-center items-center pt-4 pb-2">
      <AgentGreeterCard agentInfo={agentInfo} />
      <div className="flex w-full flex-wrap justify-center items-center md:space-x-2 mt-2">
        {agentSuggestions.map(
          (suggestion, idx) =>
            suggestion && (
              <button
                key={idx}
                className="text-white font-semibold py-1 md:py-1"
                onClick={() => handleSuggestionClick(suggestion)}
              >
                <AgentSuggestionCard msg={suggestion} />
              </button>
            )
        )}
      </div>
    </section>
  );
}

const CapabilitiesInfo = ({
  availableTools,
  agentToolIds,
}: {
  availableTools: any[];
  agentToolIds: string[];
}) => {
  const filteredTools = availableTools.filter((tool) =>
    agentToolIds.includes(tool.id)
  );

  if (!filteredTools.length) {
    return null;
  }

  return (
    <div className="flex flex-col justify-center items-center mt-4">
      <h2 className="text-xl font-semibold text-white">Tools</h2>
      <ul className="mt-2 text-center">
        {filteredTools.map((tool, idx) => (
          <li key={idx} className="text-zinc-400 text-sm">
            {tool.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

const AgentGreeterCard = ({
  agentInfo,
}: {
  agentInfo: typeof DEFAULT_AGENT_INFO;
}) => {
  const { name, avatar, description, tools, isLoading } = agentInfo;
  const [availableTools, setAvailableTools] = useState<any[]>([]);

  useEffect(() => {
    const fetchTools = async () => {
      try {
        const fetchedTools = await apiGet('api/tools');
        setAvailableTools(fetchedTools);
      } catch (error) {
        console.error('Failed to fetch tools:', error);
      }
    };
    fetchTools();
  }, []);

  const title = 'Agent Chat' || 'New chat';
  return (
    <div className="flex flex-col justify-center items-center w-full flex-1 rounded-md md:px-4 mt-4 md:mt-8">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content="chat, AI, assistant, support, service" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={avatar} />
        <meta property="og:type" content="profile" />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={avatar} />
      </Helmet>
      <LargeAvatar avatar={avatar} name={name} loading={isLoading} />
      <div className={`${(!name || !avatar) && 'hidden'} mt-2 text-center`}>
        <h1 className="text-2xl md:text-3xl font-semibold text-white">
          {name}
        </h1>
        <p className="mt-2 text-zinc-400 text-center lg:mx-10">{description}</p>
      </div>
      <CapabilitiesInfo availableTools={availableTools} agentToolIds={tools} />
    </div>
  );
};

const AgentSuggestionCard = ({ msg }: { msg: string }) => {
  return (
    <article className="flex w-64 px-4 py-2 lg:my-1 h-16 justify-center items-center bg-zinc-900 hover:bg-zinc-950 rounded-md shadow-md transition duration-300">
      <p className="text-center text-ellipsis overflow-hidden text-white font-semibold text-sm">
        {truncateText(msg, 60)}
      </p>
    </article>
  );
};
