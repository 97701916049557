import React from 'react';
import AgentList from '../components/agent/AgentList';
import { useAuth } from '../contexts/AuthContext';
import PageSidebarTemplate from '../templates/PageSidebarTemplate';

export default function Profile() {
  const { user } = useAuth();

  if (!user) {
    return (
      <PageSidebarTemplate>
        <div className="flex flex-col items-center justify-center h-full p-4">
          <h1 className="text-3xl font-bold text-amber-400">
            You must be logged in to view this page.
          </h1>
        </div>
      </PageSidebarTemplate>
    );
  }

  return (
    <PageSidebarTemplate>
      <div className="flex flex-col h-full w-full items-center overflow-y-scroll">
        <div className="max-w-3xl w-full p-2 mb-4 mx-auto">
          <UserProfileBanner user={user} />
          <AgentList />
        </div>
      </div>
    </PageSidebarTemplate>
  );
}

const UserProfileBanner = ({ user }) => {
  function getAvatarInitial(username = 'guest') {
    return username.charAt(0).toUpperCase();
  }

  return (
    <section className="w-full min-h-1/5 flex items-center md:py-4 border-b-2 border-slate-400/10">
      <div className="py-4 w-full flex justify-between text-white">
        <div className="flex space-x-2">
          <div className="w-16 h-16 rounded-full bg-zinc-900 flex justify-center items-center text-yellow-500 border-2 border-yellow-500 text-3xl pb-0.5 font-semibold">
            {getAvatarInitial(user?.details?.username)}
          </div>
          <div className="flex flex-col pt-2 pl-0.5">
            <h2 className="text-white text-sm">{`${user?.details?.first_name ?? ''} ${user?.details?.last_name ?? ''
              }`}</h2>
            <p className="text-sm text-gray-200">@{user.details.username}</p>
          </div>
        </div>
        {/* <div className="pr-2"><p>edit</p></div> */}
      </div>
    </section>
  );
};
