import Label from './Label';
import ErrorMessage from './ErrorMessage';

const TextAreaInput = ({ name, id, value, onChange, maxLength, placeholder, isRequired }) => (
  <div className="relative w-full">
    <textarea
      rows={4}
      name={name}
      id={id}
      className="block w-full rounded-md border-0 py-1.5 text-gray-100 bg-zinc-900 ring-1 ring-inset ring-gray-700 placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:outline-none focus:ring-2 focus:ring-amber-500"
      value={value}
      placeholder={placeholder}
      onChange={(e) => onChange(e)}
      maxLength={maxLength}
      required={isRequired}
    />
    {maxLength && (
      <div className="absolute right-0 text-gray-400 text-xs mt-1">
        {value.length}/{maxLength}
      </div>
    )}
  </div>
);



const TextArea = ({ name, label, id, value, onChange, errorMessage, maxLength, placeholder, isRequired }) => (
  <div>
    <Label htmlFor={name}>{label}</Label>
    <div className="mt-2">
      <TextAreaInput
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={maxLength}
        isRequired={isRequired}
      />
    </div>
    <ErrorMessage message={errorMessage} />
  </div>
);

export default TextArea;