import { useState, } from 'react';
import InputField from '../components/InputField';
import FormButton from '../components/FormButton';
import { apiPost } from '../tacktech/api';
import { useNavigate, Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

export default function NewPassword() {
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });
  const [error, setError] = useState('');
  const token = new URLSearchParams(window.location.search).get('token');
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    setError('');

    try {
      const data = { ...formData, token: token };
      await apiPost('api/auth/password-reset', data);
      setError('');
      toast.success(
        <div>
          Password changed successfully!{' '}
          <Link to='/' className='text-amber-400 underline'>
            You can now use your new password to login.
          </Link>
        </div>,
        {
          icon: '☑️',
          duration: 10000, // Extend duration to 10 seconds
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
      );
      navigate('/');
    } catch (error) {
      console.warn('Reset password failed.', error.message);
      setError(error.message);
    }
  };

  return (
    <div className='flex items-center justify-center min-h-screen bg-zinc-800 overflow-y-scroll'>
      <div className='w-full max-w-md'>
        <Toaster />
        <form
          className='bg-zinc-900 shadow-md rounded px-8 pt-6 pb-8 mb-4'
          onSubmit={handleSubmit}
        >
          <h2 className='text-2xl font-bold mb-6 text-center text-amber-400'>
            Reset your password
          </h2>
          <p className='text-s mb-6 text-center text-white'>
            Enter your new password twice to reset your account password.
          </p>
          {error && <p className='text-red-500 text-center'>{error}</p>}
          <InputField
            id='password'
            type='password'
            autoComplete='password'
            label='New Password'
            placeholder='New Password'
            value={formData.password}
            onChange={handleInputChange}
          />
          <InputField
            id='confirmPassword'
            type='password'
            autoComplete='new-password'
            label='Confirm Password'
            placeholder='Confirm Password'
            value={formData.confirmPassword}
            onChange={handleInputChange}
          />

          <div className='block text-center'>
            <FormButton text={'Update Password'} />
          </div>
        </form>
      </div>
    </div>
  );
}
