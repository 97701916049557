import React, { useState, useEffect } from 'react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { apiGet, apiPost } from '../tacktech/api';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { plans } from '../components/SubscriptionPlans';
import PageSidebarTemplate from '../templates/PageSidebarTemplate';
import { subscribeEvent } from '../utilities/analytics';

const SuccessDisplay = ({ sessionId }) => {
  const handleManageBilling = async () => {
    try {
      const response = await apiPost(
        'api/payment/create-customer-portal-session'
      );
      window.location.href = response.url;
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  return (
    <section className="text-white bg-zinc-900 p-8 rounded-lg shadow-lg max-w-xl mx-auto">
      <div className="text-center">
        <CheckCircleIcon
          className="h-16 w-16 text-yellow-500 mx-auto mb-4"
          aria-hidden="true"
        />
        <h3 className="text-3xl font-semibold mb-4">Subscribed Successfully</h3>
        <form action="/create-portal-session" method="POST" className="mt-4">
          <input
            type="hidden"
            id="session-id"
            name="session_id"
            value={sessionId}
          />
          <button className="bg-yellow-500 hover:bg-yellow-700 text-black text-sm lg:text-lg font-semibold py-2 px-4 w-42 md:w-56 rounded shadow">
            <Link onClick={handleManageBilling}>
              Manage your billing information
            </Link>
          </button>
        </form>
      </div>
    </section>
  );
};

function Plan({ id, prices, name, lookup_key, description, period, features }) {
  const isPaid = lookup_key !== null;
  const price = prices.find((price) => price.lookup_key === lookup_key);
  const navigate = useNavigate();

  const handleSubscribe = (priceId) => {
    subscribeEvent(priceId);
    stripeRedirect(priceId);
  };

  const stripeRedirect = async (priceId) => {
    try {
      const response = await apiPost('api/payment/create-checkout-session', {
        price_id: priceId,
      });
      window.location.href = response.url;
    } catch (error) {
      if (error.status === 401) {
        navigate('/login?next=/subscriptions');
      }
      console.error(error);
    }
  };

  return (
    <div
      key={id}
      className="p-8 bg-zinc-900 text-white rounded-lg shadow-lg mb-8 lg:mb-0"
    >
      <h3 id={id} className="text-2xl font-bold mb-4">
        {name}
      </h3>
      <p className="text-4xl font-bold mb-4">
        {isPaid ? `$${(price.amount_in_cents / 100).toFixed(2)}` : 'Free'}
        {isPaid && <span className="text-sm font-medium">/{period}</span>}
      </p>
      <button
        onClick={() => handleSubscribe(price.price_id)}
        className="bg-amber-400 hover:opacity-75 text-black font-semibold py-2 px-4 rounded shadow mb-4"
      >
        Buy Now
      </button>
      <p className="mb-4">{description}</p>
      <ul className="list-none space-y-2">
        {features.map((feature) => (
          <li key={feature} className="flex items-center">
            <CheckCircleIcon
              className="h-6 w-6 text-amber-100 mr-2"
              aria-hidden="true"
            />
            {feature}
          </li>
        ))}
      </ul>
    </div>
  );
}

function Skeleton() {
  return (
    <div className="p-8 bg-zinc-900 text-white rounded-lg shadow-lg animate-pulse mb-8 lg:mb-0">
      <div className="h-6 bg-gray-400 rounded w-1/3 mb-4"></div>
      <div className="h-10 bg-gray-400 rounded w-1/2 mb-6"></div>
      <div className="h-10 bg-gray-400 rounded w-1/4 mb-6"></div>
      <div className="h-4 bg-gray-400 rounded w-3/4 mb-2"></div>
      <div className="h-4 bg-gray-400 rounded w-3/4 mb-2"></div>
      <div className="h-4 bg-gray-400 rounded w-3/4 mb-2"></div>
    </div>
  );
}

function Subscriptions() {
  const [prices, setPrices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user, userLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (userLoading) {
      return;
    }

    if (user === null) {
      navigate('/login?next=/subscriptions');
    } else {
      const fetchPrices = async () => {
        setLoading(true);
        try {
          const data = await apiGet('api/payment/prices');
          setPrices(data);
        } catch (error) {
          if (error.status === 401) {
            navigate('/login?next=/subscriptions');
          }
          setError(error);
        }
        setLoading(false);
      };
      fetchPrices();
    }
  }, [user, userLoading, navigate]);

  return (
    <div className="flex flex-col h-full max-w-4xl mx-auto bg-zinc-800">
      <div className="grow flex items-center">
        <div className="grid lg:gap-8 lg:grid-cols-2 grid-cols-1">
          {loading || userLoading ? (
            <>
              <Skeleton />
              <Skeleton />
            </>
          ) : (
            plans.map((plan) => (
              <Plan key={plan.id} prices={prices} {...plan} />
            ))
          )}
          {error && <div className="text-red-500">Failed to load prices.</div>}
        </div>
      </div>
    </div>
  );
}

export default function Pricing() {
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const navigate = useNavigate();
  const { fetchUserData, user } = useAuth();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
      fetchUserData();
    }

    if (query.get('success') === 'false' || query.get('canceled')) {
      setSuccess(false);
      navigate('/subscriptions'); // Redirect to /subscriptions on cancel
    }
  }, [sessionId, navigate, fetchUserData]);


  if (!user) {
    return (
      <PageSidebarTemplate>
        <div className="flex flex-col items-center justify-center h-full p-4">
          <h1 className="text-3xl font-bold text-amber-400">You must be logged in to view this page.</h1>
        </div>
      </PageSidebarTemplate>
    );
  }

  return (
    <PageSidebarTemplate>
      <div className="bg-zinc-800 h-full p-6 overflow-y-auto">
        <div className="flex h-full items-center justify-center mx-auto">
          {success ?
            <SuccessDisplay sessionId={sessionId} />
            : <Subscriptions />}
        </div>
      </div>
    </PageSidebarTemplate>
  );
}
