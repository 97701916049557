import { useState, useEffect } from 'react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { useNavigate, useLocation } from 'react-router-dom';
import { apiGet, apiPost } from '../tacktech/api';
import { useAuth } from '../contexts/AuthContext';
import { FaTimes } from 'react-icons/fa';
import {
  primary_hover,
  hover_fade,
  card_color,
} from '../utilities/ColorPalette';
import { plans } from './SubscriptionPlans';
import { subscribeEvent } from '../utilities/analytics';

function Plan({
  id,
  prices,
  name,
  lookup_key,
  period,
  features,
  onSubscribe,
}) {
  const price = prices.find((price) => price.lookup_key === lookup_key);
  const discount = period === 'yearly' ? ((15 * 12 - 144) / (15 * 12)) * 100 : 0;

  const handleSubscribe = (priceId) => {
    subscribeEvent(priceId);
    onSubscribe(priceId);
  };

  return (
    <div
      key={id}
      className="p-4 bg-zinc-900 text-white rounded-lg border border-amber-400 mb-4 w-full mx-auto text-left relative"
    >
      <h3 className="text-xl text-amber-400 font-bold mb-2">{name}</h3>
      {discount > 0 && (
        <div className="absolute top-2 right-2 bg-amber-400 text-black text-xs font-bold px-2 py-1 rounded">
          Save {discount.toFixed(0)}%
        </div>
      )}
      <p className="text-2xl font-bold mb-2">
        {`$${(period === 'monthly' ? 15.0 : 144.0).toFixed(2)}`}
        <span className="text-lg font-medium">/{period}</span>
      </p>
      <button
        onClick={() => handleSubscribe(price.price_id)}
        className="bg-amber-400 hover:opacity-75 text-black font-semibold py-2 px-4 rounded shadow mb-4"
      >
        Subscribe Now
      </button>
      <ul className="list-none space-y-2">
        {features.map((feature) => (
          <li key={feature} className="flex items-center">
            <CheckCircleIcon
              className="h-6 w-6 text-amber-100 mr-2"
              aria-hidden="true"
            />
            {feature}
          </li>
        ))}
      </ul>
    </div>
  );
}

const SubscriptionsModal = ({ isOpen, onClose }) => {
  const [prices, setPrices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [redirectPath, setRedirectPath] = useState('/');

  useEffect(() => {
    if (isOpen) {
      setRedirectPath(location.pathname);
      const fetchPrices = async () => {
        setLoading(true);
        try {
          const data = await apiGet('api/payment/prices');
          setPrices(data);
        } catch (error) {
          if (error.status === 401) {
            navigate('/login?next=/subscriptions');
          }
          setError(error);
        }
        setLoading(false);
      };
      fetchPrices();
    }
  }, [isOpen, location.pathname, navigate]);

  const stripeRedirect = async (priceId) => {
    try {
      const response = await apiPost('api/payment/create-checkout-session', {
        price_id: priceId,
      });
      window.location.href = response.url; // Redirect to Stripe's checkout session URL
    } catch (error) {
      if (error.status === 401) {
        navigate(`/login?next=${redirectPath}`);
      }
      console.error(error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-[90]">
      <div
        className={`bg-zinc-900 p-6 rounded-lg shadow-lg relative w-full h-full md:h-fit max-w-4xl mx-auto ${card_color} overflow-y-auto`}
      >
        <button className="absolute top-2 right-2 text-white" onClick={onClose}>
          <FaTimes size={20} />
        </button>
        <h2 className="text-2xl text-amber-400 text-center mb-4">
          Choose Your Plan
        </h2>

        {loading || userLoading ? (
          <Skeleton />
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 justify-center">
            {plans.map((plan) => (
              <Plan
                key={plan.id}
                prices={prices}
                {...plan}
                onSubscribe={stripeRedirect}
              />
            ))}
          </div>
        )}

        {error && (
          <div className="text-red-500 text-center">Failed to load prices.</div>
        )}

        <div className="mt-4 text-center text-sm text-white">
          <button
            onClick={() => {
              onClose();
              navigate('/subscriber-terms');
            }}
            className={`${primary_hover} ${hover_fade} inline-block`}
          >
            Subscriber Terms
          </button>
        </div>

        <div className="text-center mt-4 text-sm text-white">
          <button
            onClick={() => {
              onClose();
              navigate('/terms');
            }}
            className={`${primary_hover} ${hover_fade} inline-block`}
          >
            Terms of Use
          </button>
          <span className="mx-2 text-white">|</span>
          <button
            onClick={() => {
              onClose();
              navigate('/privacy');
            }}
            className={`${primary_hover} ${hover_fade} inline-block`}
          >
            Privacy Policy
          </button>
        </div>
      </div>
    </div>
  );
};

function Skeleton() {
  return (
    <div className="p-8 bg-zinc-900 text-white rounded-lg shadow-lg animate-pulse mb-4">
      <div className="h-6 bg-gray-400 rounded w-1/3 mb-4"></div>
      <div className="h-10 bg-gray-400 rounded w-1/2 mb-6"></div>
      <div className="h-10 bg-gray-400 rounded w-1/4 mb-6"></div>
      <div className="h-4 bg-gray-400 rounded w-3/4 mb-2"></div>
      <div className="h-4 bg-gray-400 rounded w-3/4 mb-2"></div>
      <div className="h-4 bg-gray-400 rounded w-3/4 mb-2"></div>
    </div>
  );
}

export default SubscriptionsModal;
