import React from "react";
import FormSection from "../FormSection";
import CheckboxInput from "../CheckboxInput";

const ToolSetting = ({ tool, formData, handleInputChange }) => {
    const isChecked = formData.tools.includes(tool.id);
  
    const handleToolChange = (e) => {
      const newTools = isChecked
        ? formData.tools.filter(id => id !== tool.id)
        : [...formData.tools, tool.id];
      handleInputChange({ target: { id: 'tools', value: newTools } });
    };
  
    return (
      <CheckboxInput
        label={tool.name}
        checked={isChecked}
        onChange={handleToolChange}
        description={tool.description}
      />
    );
};

const ToolsSettings = ({ availableTools, formData, handleInputChange }) => {
    console.log("Tools", availableTools);
    return (
      <FormSection
        title="Tools Settings"
        description="Give your LLM access to tools that expand its capabilities."
      >
        {availableTools.map((tool) => (
          <ToolSetting
            key={tool.id}
            tool={tool}
            formData={formData}
            handleInputChange={handleInputChange}
          />
        ))}
      </FormSection>
    );
};

export default ToolsSettings;
