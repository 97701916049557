import { useParams } from 'react-router-dom';
import NewChatButton from './NewChatButton';
import ShareButton from './ShareButton';

export default function ChatAgentUtilityBar() {
  const { chatid, id } = useParams();

  if (!id) return;

  return (
    <div className="py-1 flex justify-between items-center space-x-1">
      <p className="md:pl-4 text-xs/3 md:text-sm/4 text-gray-200 font-medium text-left md:text-left flex-grow"></p>
      <div className="flex-shrink flex gap-1 pr-1">
        <NewChatButton id={id} chatId={chatid} />
        <ShareButton agentId={id} />
      </div>
    </div>
  );
}
