import {
  createContext,
  useReducer,
  useContext,
  useEffect,
} from 'react';
import { apiGet, apiDelete, apiPatch } from '../tacktech/api';
import { useAuth } from '../contexts/AuthContext.jsx';

const ConversationsContext = createContext();

export const ActionTypes = {
  START_UPDATE: "START_UPDATE",
  POPULATE_CONVERSATIONS: "POPULATE_CONVERSATIONS",
  ADD_CONVERSATION: "ADD_CONVERSATION",
  DELETE_CONVERSATION: "DELETE_CONVERSATION",
  UPDATE_CONVERSATION: "UPDATE_CONVERSATION",
  ERROR: "ERROR",
};

async function deleteConversation(dispatch, conversation) {
  const { id } = conversation;
  try {
    await apiDelete(`api/conversations/${id}`);
    dispatch({ type: ActionTypes.DELETE_CONVERSATION, payload: id });
    return true;
  } catch (error) {
    dispatch({ type: ActionTypes.ERROR, payload: error.message });
    console.error('An error occurred attempting to delete record from database', error);
    return false;
  }
}

async function updateConversationName(dispatch, conversation, newName) {
  try {
    await apiPatch(`api/conversations/${conversation.id}`, { name: newName });
    dispatch({ type: ActionTypes.UPDATE_CONVERSATION, payload: { ...conversation, name: newName } });
    return true;
  } catch (error) {
    dispatch({ type: ActionTypes.ERROR, payload: error.message });
    console.error('An error occurred attempting to delete record from database', error);
    return false;
  }
}

const initialConversations = {
  conversations: [],
  loading: true,
  error: null,
};

const conversationsReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.START_UPDATE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ActionTypes.POPULATE_CONVERSATIONS:
      return {
        ...state,
        conversations: action.payload,
        loading: false,
        error: false,
      };

    case ActionTypes.ADD_CONVERSATION:
      return {
        ...state,
        conversations: [...state.conversations, action.payload],
        loading: false,
        error: false,
      };
    case ActionTypes.DELETE_CONVERSATION:
      return {
        ...state,
        conversations: state.conversations.filter((conversation) => conversation.id !== action.payload),
        loading: false,
        error: false,
      };
    case ActionTypes.UPDATE_CONVERSATION:
      return {
        ...state,
        conversations: state.conversations.map((conversation) =>
          conversation.id === action.payload.id ? action.payload : conversation
        ),
        loading: false,
        error: false,
      };
    case ActionTypes.ERROR:
      if (action.payload.status === 403) {
        return {
          conversations: [],
          error: action.payload,
          loading: false,
        }
      }
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};



const ConversationsContextProvider = ({ children }) => {
  const { sessionID, user } = useAuth();
  const [conversationsInfo, dispatch] = useReducer(conversationsReducer, initialConversations);

  useEffect(() => {
    const initializeConversations = async () => {
      dispatch({ type: ActionTypes.START_UPDATE });
      if (!sessionID) {
        dispatch({ type: ActionTypes.ERROR, payload: "No session ID" });
        return;
      }
      try {
        const response = await apiGet('api/conversations');
        dispatch({ type: ActionTypes.POPULATE_CONVERSATIONS, payload: response });
      } catch (error) {
        dispatch({ type: ActionTypes.ERROR, payload: error.message });
        console.error('Failed to fetch chat history:', error);
      }
    };
    if (sessionID) {
      initializeConversations();
    }
  }, [sessionID, user]);

  const value = { conversationsInfo, dispatch };
  return (
    <ConversationsContext.Provider value={value}>{children}</ConversationsContext.Provider>
  );
};

function useConversationsContext() {
  const context = useContext(ConversationsContext);
  if (context === undefined) {
    throw new Error(
      'useConversationContext must be used within a ConversationContextProvider component'
    );
  }
  return context;
}

export { useConversationsContext, ConversationsContextProvider, deleteConversation, updateConversationName };
