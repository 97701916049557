import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/outline';
import { getFormatedCount } from '../utilities/utilities';

const ConversationCount = ({ count }) => {
    return (
        <span className="inline-flex space-x-1.5 justify-end w-full pr-5">
            <ChatBubbleLeftEllipsisIcon className="text-gray-400 size-4 mt-[0.06rem]" />
            <p className="font-light text-sm text-gray-400">{getFormatedCount(count)}</p>
        </span>
    );
};

export default ConversationCount;