import React from "react";

const CheckboxInput = ({ label, checked, onChange, description }) => (
  <div className="flex items-start">
    <div className="flex items-center h-5">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="focus:ring-indigo-500 h-4 w-4 text-black border-gray-300"
      />
    </div>
    <div className="ml-3 text-sm">
      <label className="font-medium text-amber-400">{label}</label>
      <p className="text-white">{description}</p>
    </div>
  </div>
);

export default CheckboxInput;