import React from 'react';

const LargeAvatarSkeleton = () => {
  return (
    <div className="m-4">
      <div className="rounded-full h-48 w-48 ring-4 ring-amber-400 bg-zinc-900 animate-pulse">
      </div>
    </div>
  );
};

const LargeAvatar = ({ loading, name, avatar }) => {

  if (loading) {
    return <LargeAvatarSkeleton />;
  }
  else {
    return (
      <img
        className="object-cover rounded-full h-36 w-36 ring-2 ring-amber-400 bg-zinc-900"
        src={avatar}
        alt={name}
      />
    );
  }
};

export default LargeAvatar;