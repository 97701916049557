import React, { useState, useEffect, useId } from 'react';
import { Link } from 'react-router-dom';
import { agentList, getAvatarUrl } from '../tacktech/api';
import { card_color } from '../utilities/ColorPalette';
import truncateText, { getFirstSentence } from '../utilities/utilities';
import { useAuth } from '../contexts/AuthContext';
import HorizontalCardScroller from '../components/HorizontalCardScroller';
import ConversationCount from './ConversationCount';


export default function FeaturedAgentGrid() {
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(true);
    const auth = useAuth();
    const id = useId();

    useEffect(() => {
        if (!auth.sessionID) return;

        const fetchCards = async () => {
            setLoading(true);
            const data = await agentList({
                type: 'selected',
                name: 'cardgrid',
                limit: 10,
                skip: 0,
            });
            setCards(data.agents);
            setLoading(false);
        };
        fetchCards();
    }, [auth.sessionID]);

    const getFirstSentenceWithMaxLength = (text, maxLength = 50) => {
        const sentence = getFirstSentence(text);
        if (sentence.length > maxLength) return truncateText(sentence, maxLength);
        return sentence;
    };

    return (
        <div className="flex-auto max-w-6xl mx-auto">
            <h1 className="text-xl md:text-2xl font-semibold text-gray-200 my-2 mx-1">
                Featured
            </h1>
            <div className="flex">
                <HorizontalCardScroller>
                    {loading
                        ? Array.from({ length: 4 }).map((_, index) => (
                            <div
                                key={index}
                                className={`${card_color} text-white rounded-lg animate-pulse w-72`}
                            >
                                <div className="flex items-center p-4">
                                    <div className="h-10 w-10 bg-gray-700 rounded-full"></div>
                                    <div className="flex flex-col w-2/3 space-y-2 mx-2">
                                        <div className="h-2 bg-gray-700 rounded"></div>
                                        <div className="h-2 bg-gray-700 rounded"></div>
                                        <div className="h-2 bg-gray-700 rounded mb-4"></div>
                                        <div className="h-2 w-3/4 bg-gray-700 rounded"></div>
                                        <div className="h-2 w-3/4 bg-gray-700 rounded"></div>
                                    </div>
                                </div>
                            </div>
                        ))
                        : cards.map((card) => (
                            <Link rel="prefetch" to={`/agents/${card.id}/chat`}>
                                <div
                                    key={id}
                                    className={`${card_color} text-white rounded-lg shadow-lg py-6 px-4 space-x-4 flex flex-col justify-center items-center h-full w-72  hover:bg-zinc-950`}
                                >
                                    <span className="inline-flex w-full h-full">
                                        <img
                                            src={getAvatarUrl(card.avatar_url)}
                                            alt={card.name}
                                            className="mx-2 flex-none w-10 h-10 rounded-full object-cover"
                                        />{' '}
                                        <p className="text-gray-400 font-semibold mt-0.5">
                                            {card.name}
                                        </p>
                                    </span>
                                    <ConversationCount count={card.chats} />
                                    <h3 className="text-md text-left text-gray-200">{getFirstSentenceWithMaxLength(card.tagline, 50)}</h3>
                                </div>
                            </Link>
                        ))}
                </HorizontalCardScroller>
            </div>
        </div>
    );
}
