import React from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../contexts/AuthContext';

const AdminCheck = ({ children }) => {
  const { user } = useAuth();

  // Check if the user has an admin role
  const isAdmin = user?.roles?.includes('admin');

  if (!user) {
    // Display a message if the user is not logged in
    return (
      <div className="flex flex-col items-center justify-center h-full p-4">
        <h1 className="text-3xl font-bold text-amber-400">
          You must be logged in to view this page.
        </h1>
      </div>
    );
  }

  if (!isAdmin) {
    // Display a message if the user is not an admin
    return (
      <div className="flex flex-col items-center justify-center h-full p-4">
        <h1 className="text-3xl font-bold text-amber-400">
          Access Denied: You do not have permission to view this page.
        </h1>
      </div>
    );
  }

  // If the user is an admin, render the children
  return <>{children}</>;
};

// Define prop types for better debugging and validation
AdminCheck.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AdminCheck;
