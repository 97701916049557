import React from 'react';

const ProfilePicture = ({ username, hideName }) => {
  if (!username) return null;
  const firstLetter = username.charAt(0).toUpperCase();

  return (
    <div className="flex items-center">
      <div className="relative flex-shrink-0 h-8 w-8 rounded-full bg-amber-400 flex items-center justify-center text-black text-base">
        {firstLetter}
      </div>
      {!hideName && <span className="ml-2">{username}</span>}
    </div>
  );
};

export default ProfilePicture;
