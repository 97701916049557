import { React } from 'react';
import ErrorAlert from './ErrorAlert';
import MarkdownRenderer from './MarkdownRenderer';
import { useAgentContext } from "../contexts/AgentContext"

const ChatMessage = ({
  message,
  role,
  avatar_url,
  colorLight,
  colorDark,
  isLoading,
  done,
  errorMessage
}) => {
  const agentInfo = useAgentContext();

  return (
    <>
      {role === "user" ? (
        <div className="flex px-2 py-8 text-slate-100 sm:px-6 overflow-auto">
          <div className='flex w-full flex-col items-start relative'>
            <div className="flex w-full justify-end">
              <div className="bg-zinc-700 rounded-tl-3xl rounded-tr-3xl rounded-bl-3xl rounded-br-3xl text-left mx-2 py-2.5">
                <MarkdownRenderer message={message} />
              </div>
            </div>
            {errorMessage && (
              <ErrorAlert title={"API Error"} message={errorMessage} />
            )}
          </div>
        </div>
      ) : (

        < div className="flex px-2 pb-8 mb-2 text-slate-100 sm:px-6">
          <img className='mr-2 flex h-8 w-8 rounded-full sm:mr-4 object-cover' src={agentInfo.avatar} alt="Avatar" />
          <div className='flex w-full flex-col relative'>
            <div className={`bg-zinc-800 w-full`}>
              {(isLoading && !message) ? <MessagingDots /> : null}
              <MarkdownRenderer message={message} />
            </div>
            {errorMessage && (
              <ErrorAlert title={"API Error"} message={errorMessage} />
            )}
          </div>
        </div >

      )}
    </>
  );
};

export default ChatMessage;


const MessagingDots = () => {
  return (
    <div className="flex space-x-[0.2rem] items-center justify-center flex-1 w-16 h-12 bg-zinc-700 rounded-tl-3xl rounded-tr-3xl rounded-bl-3xl rounded-br-3xl">
      <div className="rounded-full h-1 w-1 bg-zinc-400 animate-bounceBig"></div>
      <div className="rounded-full h-1 w-1 bg-zinc-400 animate-bounceBig animate-delay animation-delay-[200ms]"></div>
      <div className="rounded-full h-1 w-1 bg-zinc-400 animate-bounceBig animate-delay animation-delay-[400ms]"></div>
    </div>
  )
}
