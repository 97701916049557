import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { apiGet, getAvatarUrl } from '../tacktech/api';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from './AuthContext';

type Tool = {
  name: string, 
  description: string,
  id: string
};

type AgentInfo = {
  id: number;
  name: string;
  avatar: string;
  greeting: string;
  description: string;
  isLoading: boolean;
  suggestions: string[];
  tools: Tool[];
  userAuthorized: boolean;
};

const DEFAULT_AGENT_INFO: AgentInfo = {
  id: -1,
  name: '',
  avatar: '',
  greeting: 'How can I help you today?',
  description: '',
  isLoading: true,
  suggestions: [],
  tools: [],
  userAuthorized: true,
};

const AgentContext = createContext<AgentInfo>(DEFAULT_AGENT_INFO);

const AgentContextProvider = ({ children }: { children: ReactNode }) => {
  const { sessionID } = useAuth();
  const [agentInfo, setAgentInfo] = useState(DEFAULT_AGENT_INFO);
  const [isLoading, setIsLoading] = useState(true);
  const [userAuthorized, setUserAuthorized] = useState(true);
  const navigate = useNavigate();
  let { id, chatid } = useParams();
  let urlId = id;

  useEffect(() => {
    if (urlId && !userAuthorized) {
      navigate('/forbidden');
    }
  }, [userAuthorized, urlId, navigate, chatid]);

  useEffect(() => {
    const initializeAgent = async () => {
      if (agentInfo.id !== Number(urlId))
        setAgentInfo({ ...DEFAULT_AGENT_INFO, isLoading: true });
      try {
        const response = await apiGet(`api/agents/${urlId}`);
        const {
          id,
          name,
          description,
          suggestion_one,
          suggestion_two,
          suggestion_three,
          tools
        } = response;
        const agentImg = getAvatarUrl(response.avatar_url);

        setAgentInfo({
          id: id,
          name: name,
          avatar: agentImg,
          description: description,
          greeting: DEFAULT_AGENT_INFO.greeting,
          suggestions: [suggestion_one, suggestion_two, suggestion_three],
          isLoading: false,
          tools: tools,
          userAuthorized: true,
        });
      } catch (error: any) {
        setUserAuthorized(error.status !== 403);
        setAgentInfo({ ...DEFAULT_AGENT_INFO });
        console.error('Failed to fetch agent:', error);
      } finally {
        setIsLoading(false);
      }
    };
    if (sessionID) {
      initializeAgent();
    }
  }, [sessionID, urlId, chatid, agentInfo.id, userAuthorized]);

  return (
    <AgentContext.Provider
      value={{
        ...agentInfo,
        isLoading,
      }}
    >
      {children}
    </AgentContext.Provider>
  );
};

function useAgentContext() {
  const context = useContext(AgentContext);
  if (context === undefined) {
    throw new Error(
      'useAgentContext must be used within an AgentContextProvider component'
    );
  }
  return context;
}

export { useAgentContext, AgentContextProvider };
