import PageSidebarTemplate from '../templates/PageSidebarTemplate';
import { Link } from 'react-router-dom';

export default function ForbiddenPage() {
  return (
    <PageSidebarTemplate>
      <div className="text-white overflow-y-auto min-h-screen mx-auto h-full w-full max-w-7xl px-6 pb-16 pt-8 lg:px-8">
        <div className="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
          <p className="text-base font-semibold leading-8 text-white">403</p>
          <h1 className="mt-4 text-xl lg:text-3xl font-bold tracking-tight text-amber-400">
            We are sorry, but you do not have access to this page or resource.
          </h1>
        </div>
        <div className="mx-auto mt-16 flow-root max-w-lg sm:mt-20">
          <div className="mt-10 flex justify-center">
            <Link
              to="/"
              className="text-sm font-semibold leading-6 text-amber-400 hover:opacity-75"
            >
              <span aria-hidden="true">&larr;</span>Back to home
            </Link>
          </div>
        </div>
      </div>
    </PageSidebarTemplate>
  );
}
