import React from 'react';

export const ErrorMessage = ({ message }) => {
    function shouldDisplay() {
        if (!message) return false;
        if (Array.isArray(message) && message.length === 0) return false;
        return true;
    }

    return (
        <span className={`${shouldDisplay() ? 'visible' : 'invisible'} inline-flex items-center space-x-1`} >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 text-red-600 mt-0.5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
            </svg>


            {Array.isArray(message) ? message.map((msg, idx) => (
                <p key={idx} className="text-red-600 text-xs font-medium">{`${msg}${idx !== message.length - 1 ? "," : ""}`}</p>
            )) : <p className="text-red-600 text-xs font-medium">{message}</p>}
        </span >
    )
}


export default ErrorMessage;
