import FeaturedAgentGrid from '../components/FeaturedAgentGrid';
import RecommmendAgentGrid from '../components/RecommmendAgentGrid';
import DiscoveryAgentGrid from '../components/DiscoveryAgentGrid';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';
import PageSidebarTemplate from '../templates/PageSidebarTemplate';
import CreateAgentCTA from '../components/CreateAgentCTA';
import Hero from '../components/Hero';

const LandingPage = () => {
  return (
    <PageSidebarTemplate>
      <Helmet>
        <title>TackTech</title>
        <meta
          name="description"
          content={
            'Explore and interact with a variety of AI chatbots and assistants tailored to meet your needs.'
          }
        />
        <meta
          name="keywords"
          content="AI, chatbots, assistant, support, interactive, machine learning, artificial intelligence"
        />

        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content={'TackTech - AI Chatbots and Assistants'}
        />
        <meta
          property="og:description"
          content={
            'Explore and interact with a variety of AI chatbots and assistants tailored to meet your needs.'
          }
        />
        <meta property="og:image" content={'logo.jpg'} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={'TackTech - AI Chatbots and Assistants'}
        />
        <meta
          name="twitter:description"
          content={
            'Explore and interact with a variety of AI chatbots and assistants tailored to meet your needs.'
          }
        />
        <meta name="twitter:image" content={'logo.jpg'} />

        {/* Additional Meta Tags */}
        <meta name="author" content="TackTech Inc." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* Favicon */}
        <link rel="icon" href="favicon.ico" type="image/x-icon" />
      </Helmet>
      <div className="flex flex-col h-full w-full items-center overflow-y-auto">
        <div className="flex flex-col flex-auto w-full h-full justify-between">
          <div className="max-w-4xl w-full p-2 mx-auto space-y-8">
            <Hero />
            <FeaturedAgentGrid />
            <RecommmendAgentGrid />
            <DiscoveryAgentGrid />
            <CreateAgentCTA />
          </div>
          <Footer />
        </div>
      </div>
    </PageSidebarTemplate>
  );
};

export default LandingPage;
