import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import { apiPost, apiPatch, apiGet, getAvatarUrl } from '../tacktech/api';
import { useAuth } from '../contexts/AuthContext';
import FormButton from '../components/FormButton';
import InputField from '../components/InputField';
import TextArea from '../components/TextField';
import ImageUpload from '../components/ImageUpload';
import PageSidebarTemplate from '../templates/PageSidebarTemplate';
import AccessSettings from '../components/agent/AccessSettings';
import SuggestionSettings from '../components/agent/SuggestionSettings';
import ToolsSettings from '../components/agent/ToolSettings';
import toast from 'react-hot-toast';
import ErrorMessage from "../components/ErrorMessage";
import { agentCreation } from '../utilities/analytics';

const initialFormData = {
  name: '',
  description: '',
  tagline: '',
  suggestion_one: '',
  suggestion_two: '',
  suggestion_three: '',
  instructions: '',
  visibility: 'public',
  tools: [],
};

const fetchAgentData = async (id, setFormData, setAvatarPreview) => {
  try {
    const data = await apiGet(`api/agents/${id}`);
    const { owner, avatar_url, ...rest } = data; // Destructure to exclude the owner property
    setFormData(rest);
    setAvatarPreview(getAvatarUrl(avatar_url));
  } catch (error) {
    console.error('Failed to fetch agent data:', error);
  }
};

const saveAgentData = async (id, formData) => {
  const data = new FormData();

  Object.keys(formData).forEach((key) => {
    const value = formData[key];
    if (value == null) return;

    if (Array.isArray(value)) {
      value.forEach((item) => {
        data.append(`${key}[]`, item);
      });
    } else if (key === 'avatar' && value instanceof File) {
      data.append('avatar', value);
    } else {
      data.append(key, value);
    }
  });

  try {
    return id
      ? await apiPatch(`api/agents/${id}`, data)
      : await apiPost('api/agents', data);
  } catch (error) {
    console.error('Error saving agent data:', error);
    throw error; // Re-throw to allow calling code to handle the error
  }
};

const successMessage = (id, responseId) => (
  <div>
    Agent {id ? 'updated' : 'created'} successfully!{' '}
    <Link to={`/agents/${responseId}/chat`} className="text-amber-400 underline">
      Chat with the agent
    </Link>
  </div>
);

const handleFormError = (error, setErrors) => {
  console.error(error);
  toast.error("An error occurred trying to create Agent.", {
    icon: <div className="mx-2">❌</div>,
    duration: 2000,
    style: { borderRadius: '10px', background: '#333', color: '#fff' },
  });
  setErrors({
    message: error.message || "",
    fields: error.fields || {},
  });
};

const handleFileInput = (id, file, setFormData, setAvatarPreview) => {
  const reader = new FileReader();
  reader.onloadend = () => setAvatarPreview(reader.result);
  reader.readAsDataURL(file);
  setFormData(prev => ({ ...prev, [id]: file }));
};

const renderNotLoggedIn = () => (
  <PageSidebarTemplate>
    <div className="flex flex-col items-center justify-center h-full p-4">
      <h1 className="text-3xl font-bold text-amber-400">You must be logged in to view this page.</h1>
    </div>
  </PageSidebarTemplate>
);

export default function Agent() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [avatarUrl, setAvatarPreview] = useState('');
  const [formData, setFormData] = useState(initialFormData);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({ fields: {} });
  const [availableTools, setAvailableTools] = useState([]);

  useEffect(() => {
    const fetchTools = async () => {
      try {
        const tools = await apiGet('api/tools');
        setAvailableTools(tools);
      } catch (error) {
        console.error('Failed to fetch tools:', error);
      }
    };
    fetchTools();
  }, []);

  useEffect(() => {
    if (id) {
      fetchAgentData(id, setFormData, setAvatarPreview);
    }
    else {
      setErrors({ fields: {} })
      setFormData(initialFormData);
      setAvatarPreview(null)
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const response = await saveAgentData(id, formData);
      toast.success(successMessage(id, response.id), {
        duration: 2000,
        style: { borderRadius: '10px', background: '#333', color: '#fff' },
      });
      setErrors({ fields: {} });
      navigate(`/agents/${response.id}`);
      agentCreation();
    } catch (error) {
      handleFormError(error, setErrors);
    } finally {
      setSubmitting(false);
    }
  };

  const handleInputChange = (e) => {
    const { id, value, files } = e.target;
    if (files) {
      handleFileInput(id, files[0], setFormData, setAvatarPreview);
    }
    else {
      setFormData(prev => ({ ...prev, [id]: value }));
    }
  };

  const handleAccessChange = (e) => {
    setFormData(prev => ({ ...prev, visibility: e.target.value }));
  };


  if (!user) return renderNotLoggedIn();

  return (
    <PageSidebarTemplate>
      <Helmet>
        <title>TackTech - Agent Configuration</title>
        <meta name="description" content="Explore and interact with a variety of AI chatbots and assistants tailored to meet your needs." />
        <meta name="keywords" content="AI, chatbots, assistant, support, interactive, machine learning, artificial intelligence" />
        <meta property="og:title" content="TackTech - AI Chatbots and Assistants" />
        <meta property="og:description" content="Explore and interact with a variety of AI chatbots and assistants tailored to meet your needs." />
        <meta property="og:image" content="logo.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="TackTech - AI Chatbots and Assistants" />
        <meta name="twitter:description" content="Explore and interact with a variety of AI chatbots and assistants tailored to meet your needs." />
        <meta name="twitter:image" content="logo.jpg" />
        <meta name="author" content="TackTech Inc." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="icon" href="favicon.ico" type="image/x-icon" />
      </Helmet>
      <div className="h-full flex w-full flex-col justify-between overflow-y-auto">
        <form onSubmit={handleSubmit} className='flex flex-col flex-auto w-full h-full p-4 max-w-4xl mx-auto space-y-4'>
          <h1 className="text-2xl text-yellow-500 font-bold mb-2">Create Your Agent</h1>
          <div>
            <InputField
              id="name"
              type="text"
              label="Name"
              placeholder="e.g Gym Trainer"
              className="bg-zinc-950"
              value={formData.name}
              errorMessage={errors.fields.name}
              maxLength={20}
              onChange={handleInputChange}
              isRequired={true}
            />
          </div>
          <div className="pb-6">
            <ImageUpload name="avatar" avatarUrl={avatarUrl} handleAvatarChange={handleInputChange} />
            <ErrorMessage message={errors.fields.avatar} />
          </div>
          <div>
            <InputField
              id="tagline"
              type="text"
              label="Tagline"
              placeholder="Add a short tagline to describe your agent."
              maxLength={50}
              value={formData.tagline}
              errorMessage={errors.fields.tagline}
              onChange={handleInputChange}
              isRequired={true}
            />
          </div>
          <div>
            <TextArea
              id="description"
              name="description"
              label="Description"
              value={formData.description}
              errorMessage={errors.fields.description}
              placeholder={'Write a description that users will see to help understand what this agent can do. Try and answer the question "How would your agent describe themselves?".'}
              maxLength={500}
              onChange={handleInputChange}
              isRequired={true}
            />
          </div>
          <div className='pt-6'>
            <TextArea
              id="instructions"
              name="instructions"
              label="Instructions"
              value={formData.instructions}
              errorMessage={errors.fields.instructions}
              maxLength={10000}
              placeholder={"Specify instructions on how you want your agent to behave. The more detailed and concise the instructions you provide are, the more your agent will be able to meet your wants and needs."}
              onChange={handleInputChange}
              isRequired={true}
            />
          </div>
          <SuggestionSettings formData={formData} errors={errors} handleInputChange={handleInputChange} />
          <AccessSettings formData={formData} handleAccessChange={handleAccessChange} />
          <ToolsSettings availableTools={availableTools} formData={formData} handleInputChange={handleInputChange} />

          <div className="py-2">
            <div className="mt-6 flex items-center justify-end gap-4">
              <Link to={location.state?.from || '/'} className="font-bold text-amber-400 hover:opacity-30">
                Back
              </Link>
              <FormButton id="agent-button" text={id ? "Update" : "Create"} submitting={submitting} onClick={agentCreation} />
            </div>
          </div>
        </form>
      </div>
    </PageSidebarTemplate >
  );
}