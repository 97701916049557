import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import LoginModal from './LoginModal';

const CreateAgentCTA = () => {
  const { user } = useAuth();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const handleLoginModalOpen = () => {
    setIsLoginModalOpen(true);
  };

  const handleLoginModalClose = () => {
    setIsLoginModalOpen(false);
  };

  return (
    <>
      <section className="relative max-w-4xl w-full lg:max-h-[550px] flex-auto mx-auto bg-zinc-900 md:mt-12 mb-6 py-12 md:py-6 rounded-lg bg-right-bottom bg-local bg-no-repeat lg:bg-contain bg-none lg:bg-cta-circle">
        <div className="p-4 md:px-14 md:py-8 space-y-1">
          <AgentAvatarTrio />
          <h1 className="text-2xl font-medium md:text-3xl text-left text-white pt-2">
            Create your own Agent
          </h1>
          <p className="md:w-2/3 md:pr-6 text-sm md:text-base leading-snug text-zinc-200 pb-4 md:pb-6 pt-2 rounded-lg">
            You can create custom agents and tailor them to your specific needs!
            By creating your own agent you can specify how you want them to
            interact, what they specialize in, and more.
          </p>
          {user ? (
            <Link to="/agents/create">
              <button className="bg-yellow-500 hover:bg-yellow-400 active:bg-yellow-600 text-sm md:text-base px-6 py-4 rounded-lg font-bold">
                Create your own Agent
              </button>
            </Link>
          ) : (
            <button
              onClick={handleLoginModalOpen}
              className="bg-yellow-500 hover:bg-yellow-400 active:bg-yellow-600 text-sm md:text-base px-6 py-4 mt-2 rounded-lg font-bold"
            >
              Login to create agents
            </button>
          )}
        </div>
      </section>
      {isLoginModalOpen && (
        <LoginModal isOpen={isLoginModalOpen} onClose={handleLoginModalClose} />
      )}
    </>
  );
};

const AgentAvatarTrio = () => {
  return (
    <div className="flex pt-6">
      <div className="relative h-7 w-14 rounded-bl-full rounded-br-full bg-black">
        <div className="absolute bottom-1 left-1 h-12 w-12 rounded-full bg-theme-purple"></div>
        <img
          className="absolute bottom-1 left-1 h-12 w-12 rounded-full object-cover bg-top opacity-90"
          src="./agents/creative-agent.webp"
          alt="creative-agent"
        />
      </div>

      <div className="relative h-7 w-14 rounded-bl-full rounded-br-full bg-black">
        <div className="absolute bottom-1 left-1 h-12 w-12 rounded-full bg-theme-purple"></div>
        <img
          className="absolute bottom-1 left-1 h-12 w-12 rounded-full object-cover opacity-85"
          src="./agents/counselor-agent.webp"
          alt="counselor-agent"
        />
      </div>

      <div className="relative h-7 w-14 rounded-bl-full rounded-br-full bg-black">
        <div className="absolute bottom-1 left-1 h-12 w-12 rounded-full bg-theme-purple"></div>
        <img
          className="absolute bottom-1 left-1 h-12 w-12 rounded-full object-cover opacity-90"
          src="./agents/tech-agent.webp"
          alt="tech-agent"
        />
      </div>
    </div>
  );
};

export default CreateAgentCTA;
